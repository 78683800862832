import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader, FormText } from '../../../components/typography/PopoverTypography';
import { FeatureFlagResponse } from '../../../services/FrontendMeta/frontendMetaService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  featureFlag: FeatureFlagResponse;
  onConfirmPress: (enabled: boolean) => void;
}

const FeatureFlagsUpdatePopover = ({ featureFlag, onConfirmPress }: Props) => {
  const { t } = useTranslation('manageContent');

  return (
    <FormContainer>
      <FormHeader>
        {t('feature-flags.update-dialog-title')} <b>{`${featureFlag.name}`} </b>
      </FormHeader>
      <FormText>{t('feature-flags.update-dialog-description')}</FormText>

      <ConfirmButton
        label={t('feature-flags.update-dialog-confirm')}
        onClick={() => onConfirmPress(!featureFlag.enabled)}
      />
    </FormContainer>
  );
};

export default FeatureFlagsUpdatePopover;
