import i18n from '../../assets/translations/config';

export interface NavItemType {
  name: string;
  path: string;
  subItems?: NavItemType[];
}

export const messagesNavItem: NavItemType = { name: i18n.t('messages:title'), path: '/messages/' };

export const navItems: NavItemType[] = [
  { name: i18n.t('campaigns:title'), path: '/campaigns' },
  {
    name: i18n.t('items:title'),
    path: '/items',
    subItems: [
      { name: i18n.t('items:title'), path: '/items/list' },
      { name: i18n.t('items:auctions.title-unpaid'), path: '/items/auctions-unpaid' },
      { name: i18n.t('items:auctions.title-deleted'), path: '/items/auctions-deleted' },
    ],
  },
  {
    name: i18n.t('users:title'),
    path: '/users',
    subItems: [
      { name: i18n.t('users:title-blocked'), path: '/users/blocked' },
      { name: i18n.t('users:title-find'), path: '/users/find-user' },
      { name: i18n.t('users:title-admins'), path: '/users/admins' },
      { name: i18n.t('users:title-fundraisers'), path: '/users/fundraisers' },
    ],
  },
  {
    name: i18n.t('orders:title'),
    path: '/orders',
    subItems: [
      { name: i18n.t('orders:title-list-orders'), path: '/orders/list' },
      { name: i18n.t('orders:title-refund-requests'), path: '/orders/refund-requests' },
    ],
  },
  {
    name: i18n.t('payments:title'),
    path: '/payments',
    subItems: [
      { name: i18n.t('payments:withdraw-money.title'), path: '/payments/withdraw-money' },
      { name: i18n.t('payments:manual-refunds.title'), path: '/payments/manual-refunds' },
      { name: i18n.t('payments:transactions.title'), path: '/payments/transactions' },
      { name: i18n.t('payments:payment-providers.title'), path: '/payments/providers' },
      { name: i18n.t('payments:provider-limits.title'), path: '/payments/provider-limits' },
    ],
  },
  {
    name: i18n.t('manageContent:title'),
    path: '/manage-content',
    subItems: [
      { name: i18n.t('manageContent:tags.title'), path: '/manage-content/tags' },
      { name: i18n.t('manageContent:unsafe-content.title'), path: '/manage-content/unsafe-content-reports' },
      { name: i18n.t('manageContent:feature-flags.title'), path: '/manage-content/feature-flags' },
      { name: i18n.t('manageContent:geolocation.title'), path: '/manage-content/geolocation' },
    ],
  },
  {
    name: i18n.t('shipments:title'),
    path: '/shipment',
    subItems: [
      { name: i18n.t('shipments:shipment-errors.title'), path: '/shipment/error' },
      { name: i18n.t('shipments:shipment-costs.title'), path: '/shipment/aggregated-costs' },
    ],
  },
  { name: i18n.t('notifications:title'), path: '/notifications/admin-channels' },
  {
    name: i18n.t('maintenance:title'),
    path: '/maintenance',
    subItems: [
      { name: i18n.t('maintenance:cemetery.title'), path: '/maintenance/cemetery' },
      { name: i18n.t('maintenance:recovery.title'), path: '/maintenance/reprocessing' },
    ],
  },
];
