import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../../components/containers/PageContainer';
import { theme } from '../../../assets/styles/theme';
import ProviderLimitsTable from './components/ProviderLimitsTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const ProviderLimits = () => {
  return (
    <PageContainer>
      <Container>
        <ProviderLimitsTable />
      </Container>
    </PageContainer>
  );
};

export default ProviderLimits;
