import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CemeteryDto } from '../../../services/Maintenance/maintenanceService.dto';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

const ButtonContainer = styled.div`
  width: 20px;
  margin: 2px;
`;

interface Props {
  messageDto: CemeteryDto;
  onDeleteClick: (message: CemeteryDto) => void;
  onRequeueClick: (message: CemeteryDto, anchor: SVGSVGElement) => void;
  onRetryClick: (message: CemeteryDto) => void;
}

const CemeteryActions = (props: Props) => {
  const { t } = useTranslation('maintenance');
  return (
    <Container>
      <ButtonContainer title={t('cemetery.delete-tooltip')}>
        {!props.messageDto.deleted && (
          <DeleteForeverIcon style={iconStyle} onClick={() => props.onDeleteClick(props.messageDto)} />
        )}
      </ButtonContainer>
      <ButtonContainer title={t('cemetery.requeue-tooltip')}>
        <EditIcon style={iconStyle} onClick={elem => props.onRequeueClick(props.messageDto, elem.currentTarget)} />
      </ButtonContainer>
      <ButtonContainer title={t('cemetery.retry-tooltip')}>
        <ReplayIcon style={iconStyle} onClick={() => props.onRetryClick(props.messageDto)} />
      </ButtonContainer>
    </Container>
  );
};

export default CemeteryActions;
