import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 320px;
  text-align: left;
`;

export type VerificationMessages =
  | 'SUCCESSFULLY_CONFIRMED'
  | 'USER_ALREADY_CONFIRMED'
  | 'USER_NOT_EXISTS'
  | 'NO_PIN'
  | 'PHONE_VERIFIED';

interface Props {
  content?: number | VerificationMessages;
}

export interface PinDto {
  pin: number;
}

const VerificationPopover = ({ content }: Props) => {
  const { t } = useTranslation('users');

  const resolveMessage = () => {
    switch (content) {
      case 'SUCCESSFULLY_CONFIRMED':
        return t('manual-verification.successfully-confirmed');
      case 'USER_ALREADY_CONFIRMED':
        return t('manual-verification.already-confirmed');
      case 'USER_NOT_EXISTS':
        return t('manual-verification.not-exists');
      case 'NO_PIN':
        return t('manual-verification.no-pin');
      case 'PHONE_VERIFIED':
        return t('manual-verification.phone-verified');
      default:
        return t('manual-verification.last-pin', { pin: content });
    }
  };

  return (
    <FormContainer>
      <FormHeader>
        <b>{content && resolveMessage()}</b>
      </FormHeader>
    </FormContainer>
  );
};

export default VerificationPopover;
