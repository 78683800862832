import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import RectangularButton from '../../../components/buttons/RectangularButton';
import { SectionHeader } from '../../../components/typography/Headers';
import { theme } from '../../../assets/styles/theme';
import PriceInput from '../../../components/inputs/PriceInput';
import { usePopup } from '../../../providers/PopupProvider';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { CashDonationFromDialogDto } from '../utils/types';
import { useProcessCashDonations } from '../hooks/useProcessCashDonations';
import ConfirmedUserSelector from '../../users/components/userSelector/ConfirmedUserSelector';
import { useError } from '../../../providers/useError';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { FormTable, TableWrapper } from '../../../components/forms/Containers';
import FormRow from '../../../components/forms/FormRow';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const DonateButton = styled(RectangularButton)`
  width: 100%;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
  border-radius: 0;
`;

const StyledCheckbox = styled(Checkbox)`
  transform: scale(1.5);

  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${theme.color.red};
  }
`;

interface Props {
  campaign: CampaignAdminDto;
  onDonationCompleted: () => void;
}

const AddManualDonationForm = ({ campaign, onDonationCompleted }: Props) => {
  const { t } = useTranslation('campaigns');
  const { showConfirmPopover } = usePopup();
  const { errorMessage } = useError();
  const { process, isProcessing } = useProcessCashDonations();
  const [user, setUser] = useState<UserDetailsDto>();
  const [amount, setAmount] = useState(0);
  const [anonymous, setAnonymous] = useState(false);
  const [comment, setComment] = useState('');
  const userInfo = useMemo(() => (user ? `${user.firstName} ${user.lastName} (#${user.id})` : ''), [user]);

  const handleOnClick = () => {
    if (!user || !amount || !comment) {
      errorMessage('Invalid cash donation request');
      return;
    }
    const cashDonation: CashDonationFromDialogDto = {
      campaignId: campaign.id,
      userId: user.id,
      donationAmount: amount,
      currency: 'USD',
      anonymous: anonymous,
      comment: comment,
    };
    showConfirmPopover(
      t('campaigns.manual-donations.confirmation-question', {
        amount: amount,
        currency: cashDonation.currency,
        user: userInfo,
      }),
      () => process(cashDonation, onDonationCompleted)
    );
  };

  return (
    <FormContainer>
      {isProcessing && <LoadingIndicator />}
      {!isProcessing && (
        <>
          <SectionHeader>{t('campaigns.manual-donations.header')}</SectionHeader>
          <TableWrapper>
            <FormTable>
              <tbody>
                <FormRow label={t('campaigns.manual-donations.campaign')}>{campaign.name}</FormRow>
                <FormRow label={t('campaigns.manual-donations.user')}>
                  <ConfirmedUserSelector user={user} onUserSelected={setUser} />
                </FormRow>
                <FormRow label={t('campaigns.manual-donations.amount')}>
                  <PriceInput onPriceChange={setAmount} price={amount} currency={'USD'} availableCurrencies={['USD']} />
                </FormRow>
                <FormRow label={t('campaigns.manual-donations.anonymous')}>
                  <StyledCheckbox checked={anonymous} onChange={(event, checked) => setAnonymous(checked)} />
                </FormRow>
                <FormRow label={t('campaigns.manual-donations.comment')}>
                  <InputWithLabel label={''} onChange={setComment} value={comment} multiline={true} rows={1} />
                </FormRow>
              </tbody>
            </FormTable>
          </TableWrapper>

          <DonateButton
            label={t('campaigns.manual-donations.donate-btn')}
            onClick={handleOnClick}
            disabled={!user || !amount || !comment}
          />
        </>
      )}
    </FormContainer>
  );
};

export default AddManualDonationForm;
