import { useTranslation } from 'react-i18next';
import { usePopup } from '../../../providers/PopupProvider';
import UpdateFundraiserPageForm from '../components/UpdateFundraiserPageForm';
import { FundraiserDetailsDto, FundraiserPageUpdateDto } from '../../../services/User/userService.dto';
import { userService } from '../../../services/User/userService';
import { useError } from '../../../providers/useError';

export const useUpdateFundraiserPage = (onUpdated?: () => void) => {
  const { t } = useTranslation('users');
  const { showPopover, hidePopover, showConfirmPopover } = usePopup();
  const { errorMessage } = useError();

  const handleSave = (userId: number, fundraiserPageUpdateDto: FundraiserPageUpdateDto) => {
    const onConfirm = () => {
      userService
        .updateFundraiserPage(userId, fundraiserPageUpdateDto)
        .then(() => {
          hidePopover();
          onUpdated?.();
        })
        .catch(error => errorMessage(error?.response?.data?.message));
    };
    showConfirmPopover(t('fundraiser-page.save-confirmation', { userId }), onConfirm);
  };
  const handleDelete = (userId: number) => {
    const onConfirm = () => {
      userService
        .deleteFundraiserPage(userId)
        .then(() => {
          hidePopover();
          onUpdated?.();
        })
        .catch(error => errorMessage(error?.response?.data?.message));
    };
    showConfirmPopover(t('fundraiser-page.delete-confirmation', { userId }), onConfirm);
  };

  const showUpdateFundraiserPagePopover = (user: FundraiserDetailsDto) => {
    showPopover(
      <UpdateFundraiserPageForm
        fundraiser={user}
        onSave={fundraiserPageUpdateDto => handleSave(user.id, fundraiserPageUpdateDto)}
        onCancel={() => hidePopover()}
        onDelete={() => handleDelete(user.id)}
      />
    );
  };

  return { showUpdateFundraiserPagePopover };
};
