import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { removeNonNumberChar } from '../../../utils/textUtils';
import { FormHeader } from '../../../components/typography/PopoverTypography';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 400px;
`;

interface Props {
  onContinuePressed: (messageCount: number) => void;
}

const CemeteryPullPopover = ({ onContinuePressed }: Props) => {
  const { t } = useTranslation('maintenance');
  const [value, setValue] = useState<string>('');

  const setOnlyIfNumber = (val: string) => {
    setValue(removeNonNumberChar(val));
  };

  const valueToNumber = () => {
    onContinuePressed(parseInt(value));
  };

  return (
    <FormContainer>
      <FormHeader>{t('cemetery.popover.header-label')}</FormHeader>
      <InputWithLabel label={t('cemetery.popover.count-input')} onChange={setOnlyIfNumber} value={value} />
      <ButtonsWrapper>
        <ConfirmButton label={t('common:confirm-btn')} onClick={valueToNumber} disabled={value === '0'} />
      </ButtonsWrapper>
    </FormContainer>
  );
};

export default CemeteryPullPopover;
