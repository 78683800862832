import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../../components/typography/PopoverTypography';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FundraiserDetailsDto } from '../../../services/User/userService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 320px;
`;

interface Props {
  fundraiser: FundraiserDetailsDto;
  onConfirmPressed: (userId: number) => void;
}

const FundraiserVerificationPopover = ({ fundraiser, onConfirmPressed }: Props) => {
  const { t } = useTranslation('users');

  return (
    <FormContainer>
      <FormHeader>
        {t('fundraiser-verification-reminder', { username: `${fundraiser.firstName} ${fundraiser.lastName}` })}
      </FormHeader>
      <ConfirmButton label={t('fundraiser-verification-btn')} onClick={() => onConfirmPressed(fundraiser.id)} />
    </FormContainer>
  );
};

export default FundraiserVerificationPopover;
