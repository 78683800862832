import { useEffect, useState } from 'react';
import { paymentService } from '../../../../services/Payment/paymentService';
import { ProviderInfoDto } from '../../../../services/Payment/paymentService.dto';

export const useProviders = () => {
  const [providers, setProviders] = useState<ProviderInfoDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    paymentService
      .fetchProviders()
      .then(response => setProviders(response.data))
      .finally(() => setIsLoading(false));
  }, []);

  return { providers, isLoading };
};
