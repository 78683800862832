import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableFilterStateDto } from '../../../components/table/DataTable';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { dateUtils } from '../../../utils/dateUtils';
import { frontendMetaService } from '../../../services/FrontendMeta/frontendMetaService';
import { FeatureFlagResponse } from '../../../services/FrontendMeta/frontendMetaService.dto';
import DataTableWithFilters from '../../../components/table/DataTableWithFilters';
import { TableFilter } from '../../../components/table/FiltersPanel';
import CheckboxInputWithLabel from '../../../components/inputs/CheckboxInputWithLabel';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { useError } from '../../../providers/useError';
import { usePopup } from '../../../providers/PopupProvider';
import FeatureFlagsUpdatePopover from './FeatureFlagsUpdatePopover';

const PLATFORMS = ['ios', 'android'];

const FeatureFlagsTable = () => {
  const { t } = useTranslation('manageContent');
  const dataTableRef = useRef<DataTableRef>(null);

  const { appVersions } = useGlobalData();
  const { handleError } = useError();
  const { showPopover, hidePopover } = usePopup();

  const columns: Column<FeatureFlagResponse>[] = [
    {
      id: 'id',
      title: 'ID',
      isSortable: true,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'feature',
      title: t('feature-flags.feature'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.name,
    },
    {
      id: 'enabled',
      title: t('feature-flags.enabled'),
      isSortable: false,
      align: 'center',
      renderCell: data => (
        <CheckboxInputWithLabel
          alignCenter={true}
          label={booleanValuesMap.get(data.enabled)!}
          checked={data.enabled}
          onChange={() => openUpdateDialog(data)}
        />
      ),
    },
    {
      id: 'description',
      title: t('feature-flags.description'),
      isSortable: false,
      align: 'center',
      renderCell: data => data.description,
    },
    {
      id: 'defaultValue',
      title: t('feature-flags.default-value'),
      isSortable: false,
      align: 'center',
      renderCell: data => booleanValuesMap.get(data.defaultValue)!,
    },
    {
      id: 'lastChangeDate',
      title: t('feature-flags.last-change-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.lastChangeDate && dateUtils.formatDateDate(new Date(data.lastChangeDate)),
    },
    {
      id: 'lastChangeUserId',
      title: t('feature-flags.last-change-user-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.lastChangeUserId,
    },
  ];

  const booleanValuesMap = new Map<boolean, string>([
    [true, t('feature-flags.true')],
    [false, t('feature-flags.false')],
  ]);

  const filters: TableFilter[] = [
    {
      type: 'dropdown',
      name: 'appVersion',
      options: appVersions.map(it => it.appVersion),
      hideEmptyOption: true,
      label: t('feature-flags.app-version'),
    },
    {
      type: 'dropdown',
      name: 'platform',
      options: PLATFORMS,
      hideEmptyOption: true,
      label: t('feature-flags.platform'),
    },
  ];

  const onTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const appVersion = tableState.filtersMap?.get('appVersion');
    const platform = tableState.filtersMap?.get('platform');
    if (appVersion && platform) {
      return frontendMetaService
        .getVersion(appVersion, platform)
        .then(response => ({ content: response.data.featureFlags }));
    }
    return Promise.resolve({ content: [] });
  }, []);

  const openUpdateDialog = (featureFlag: FeatureFlagResponse) => {
    showPopover(
      <FeatureFlagsUpdatePopover
        featureFlag={featureFlag}
        onConfirmPress={enabled => confirmUpdate(enabled, featureFlag)}
      />
    );
  };

  const confirmUpdate = (enabled: boolean, featureFlag: FeatureFlagResponse) =>
    frontendMetaService
      .updateFeatureFlag(featureFlag.id, enabled)
      .then(() => hidePopover())
      .then(() => dataTableRef.current?.refresh())
      .catch(error => handleError(error));

  return (
    <DataTableWithFilters
      ref={dataTableRef}
      columns={columns}
      filters={filters}
      hideApplyButton={true}
      refreshOnFiltersChange={true}
      onTableStateChanged={onTableStateChange}
      disablePagination={true}
    />
  );
};

export default FeatureFlagsTable;
