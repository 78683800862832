import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import RectangularButton from './RectangularButton';

export const ConfirmButton = styled(RectangularButton)`
  min-width: 150px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 15px;
`;

export const CancelButton = styled(RectangularButton)<{ disabled?: boolean }>`
  background-color: ${props => (props.disabled ? theme.color.disabled : theme.color.white)};
  color: ${props => (props.disabled ? theme.color.white : theme.color.red)};
  border: 2px solid ${props => (props.disabled ? theme.color.disabled : theme.color.red)};
`;
