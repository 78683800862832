import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column, TableFilterStateDto } from '../../../../components/table/DataTable';
import { DataTableRef } from '../../../../components/table/dataTableRef';
import { TableFilter } from '../../../../components/table/FiltersPanel';
import DataTableWithFilters from '../../../../components/table/DataTableWithFilters';
import { paymentService } from '../../../../services/Payment/paymentService';
import {
  MANUAL_REFUND_OPTIONS,
  ManualRefundDto,
  ManualRefundStatus,
} from '../../../../services/Payment/paymentService.dto';
import { dateUtils } from '../../../../utils/dateUtils';
import CellWithNavigate from '../../../../components/table/CellWithNavigate';
import CellWithPopover from '../../../../components/table/CellWithPopover';
import UserDetailsCard from '../../../users/components/UserDetailsCard';
import { useError } from '../../../../providers/useError';
import { usePopup } from '../../../../providers/PopupProvider';
import { convertFiltersToManualRefundFilterDto } from '../utils/manualRefundsConverter';
import ManualRefundsActions from './ManualRefundsActions';
import ManualRefundsDialog from './ManualRefundsUpdateDialog';

type UrlParams = {
  orderId: string;
};

const ManualRefundsTable = () => {
  const { t } = useTranslation('payments');
  const dataTableRef = useRef<DataTableRef>(null);
  const { orderId } = useParams<UrlParams>();

  const { handleError } = useError();
  const { showPopover, hidePopover } = usePopup();

  const columns: Column<ManualRefundDto>[] = [
    {
      id: 'orderId',
      title: t('manual-refunds.columns.order-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/orders/list/${data.orderId}`}
          title={t('manual-refunds.go-to-orders-tooltip', { id: data.orderId })}>
          {data.orderId}
        </CellWithNavigate>
      ),
    },
    {
      id: 'transactionId',
      title: t('manual-refunds.columns.transaction-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.transactionId,
    },
    {
      id: 'referenceTransactionId',
      title: t('manual-refunds.columns.reference-transaction-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.referenceTransactionId,
    },
    {
      id: 'status',
      title: t('manual-refunds.columns.status'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.status,
    },
    {
      id: 'creationDate',
      title: t('manual-refunds.columns.creation-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.creationDate)),
    },
    {
      id: 'updateDate',
      title: t('manual-refunds.columns.update-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.updateDate)),
    },
    {
      id: 'updatedBy',
      title: t('manual-refunds.columns.updated-by'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.updatedBy,
    },
    {
      id: 'notes',
      title: t('manual-refunds.columns.notes'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.notes,
    },
    {
      id: 'totalAmount',
      title: t('manual-refunds.columns.total-amount'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.totalAmount,
    },
    {
      id: 'currency',
      title: t('manual-refunds.columns.currency'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.currency,
    },
    {
      id: 'payerId',
      title: t('manual-refunds.columns.payer-id'),
      isSortable: true,
      align: 'center',
      renderCell: data =>
        data.payerId && (
          <CellWithPopover popover={<UserDetailsCard userId={data.payerId} />}>{data.payerId}</CellWithPopover>
        ),
    },
    {
      id: 'actions',
      title: t('manual-refunds.columns.actions'),
      align: 'center',
      minWidth: 40,
      maxWidth: 60,
      renderCell: data => <ManualRefundsActions refund={data} updateStatus={openUpdateDialog} />,
    },
  ];

  const filters: TableFilter[] = [
    {
      type: 'numeric',
      name: 'orderId',
      label: t('manual-refunds.filters.order-id'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...MANUAL_REFUND_OPTIONS],
      label: t('manual-refunds.filters.status'),
    },
    {
      type: 'numeric',
      name: 'payerId',
      label: t('manual-refunds.filters.payer-id'),
    },
    {
      type: 'numeric',
      name: 'transactionId',
      label: t('manual-refunds.filters.transaction-id'),
    },
    {
      type: 'text',
      name: 'refTransactionId',
      label: t('manual-refunds.filters.reference-transaction-id'),
    },
  ];

  const filterInitState = useMemo(() => {
    const orderIdFromPath = orderId ? parseInt(orderId) : undefined;
    if (orderIdFromPath && Number.isInteger(orderIdFromPath)) {
      return new Map([['orderId', orderIdFromPath]]);
    } else {
      return new Map();
    }
  }, [orderId]);

  const onTableStateChange = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const manualRefundFilter = convertFiltersToManualRefundFilterDto(tableState);
    return paymentService.fetchManualRefunds(manualRefundFilter).then(response => response.data);
  }, []);

  const openUpdateDialog = (refund: ManualRefundDto) => {
    showPopover(<ManualRefundsDialog manualRefund={refund} onConfirmPress={confirmUpdate} />);
  };

  const confirmUpdate = (id: number, notes: string, status: ManualRefundStatus) =>
    paymentService
      .updateManualRefund(id, { notes, status })
      .then(() => hidePopover())
      .then(() => dataTableRef.current?.refresh())
      .catch(error => handleError(error));

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        hideApplyButton={true}
        refreshOnFiltersChange={true}
        filterInitState={filterInitState}
        onTableStateChanged={onTableStateChange}
      />
    </>
  );
};

export default ManualRefundsTable;
