export const MANUAL_REFUND_OPTIONS = ['TODO', 'IN_PROGRESS', 'DONE', 'REJECTED'] as const;
export const TRANSACTION_STATUS_OPTIONS = ['STARTED', 'SUCCESSFUL', 'FAILED'] as const;
export const PAYMENT_AGENT_OPTIONS = ['Amex', 'DinersClub', 'Discover', 'JCB', 'MasterCard', 'Visa', 'None', 'Unknown'];

export type ManualRefundStatus = (typeof MANUAL_REFUND_OPTIONS)[number];
export type TransactionStatus = (typeof TRANSACTION_STATUS_OPTIONS)[number];
export type TransactionType = 'AUTH_AND_CAPTURE' | 'CAPTURE' | 'AUTH_ONLY' | 'REFUND' | 'VOID';

export const CURRENCIES = ['USD'];
export const DEFAULT_CURRENCY = 'USD';

export interface ManualRefundDto {
  id: number;
  transactionId: number;
  status: ManualRefundStatus;
  creationDate: string;
  updateDate: string;
  updatedBy: string;
  notes: string;
  totalAmount: number;
  orderId: number;
  referenceTransactionId: string;
  payerId: number;
  currency: string;
}

export interface ManualRefundFilterDto {
  page: number;
  size: number;
  sort?: string;
  orderId?: number;
  status?: ManualRefundStatus;
  payerId?: number;
  transactionId?: number;
  refTransactionId?: string;
}

export interface ManualRefundUpdateDto {
  notes: string;
  status: ManualRefundStatus;
}

export interface NewCashTransactionDto {
  orderId: number;
  cashAmount: number;
  currency: string;
  comment: string;
}

export interface TransactionDto {
  id: number;
  orderId: number;
  payerId?: number;
  paymentId: number;
  paymentMethodId?: number;
  money: {
    amountTotal: number;
    currency: string;
  };
  status: TransactionStatus;
  transactionType: TransactionType;
  transactionMetadata: {
    transactionId: number;
  };
}

export type PaymentErrorType =
  | 'PAYMENT_PROVIDER_PROCESSING'
  | 'PAYMENT_DECLINED'
  | 'PAYMENT_DISABLED'
  | 'INTERNAL_PROCESSING'
  | 'PAYMENT_METHOD_NOT_EXISTS'
  | 'ORDER_PROCESSED'
  | 'ORDER_NOT_OWN'
  | 'COULD_NOT_OBTAIN_LOCK'
  | 'BILLING_ADDRESS_VALIDATION'
  | 'CASH_DATA_INVALID'
  | 'ORDER_TYPE_INVALID'
  | 'ADMIN_COMMENT_LENGTH_VALIDATION';

export interface TransactionErrorResponseDto {
  errorType: PaymentErrorType;
  transactionId?: number;
}

export interface TransactionInfoDto {
  transactionId: number;
  transactionDate: string;
  transactionStatus: string;
  amount: number;
  currency: string;
  errors: boolean;
  transactionType: string;
  methodType: string;
  provider: string;
  orderId?: number;
  payerId?: number;
  paymentAgent?: string;
  overLimit: boolean;
}

export interface TransactionErrorInfoDto {
  transactionId: number;
  errorDate: string;
  description: string;
  errorType: string;
}

export interface TransactionsFilterDto {
  page: number;
  size: number;
  sort?: string;
  id?: number;
  status?: TransactionStatus[];
  paymentAgent?: string[];
  currency?: string[];
  errors?: boolean;
  orderId?: number;
  payerId?: number;
  totalAmountFrom?: number;
  totalAmountTo?: number;
  creationDateDay?: string;
  overLimit?: boolean;
}

export interface PaymentInfoDto {
  id: number;
  type: string;
  enabled: boolean;
  comment: string;
  providerType: string;
  providerId: number;
  configReference: string;
}

export type PaymentMethodType = 'googlePay' | 'applePay' | 'creditCard' | 'cash';

export type FinixEnvironment = 'sandbox' | 'live';

export interface PaymentConfigDto {
  maxItemPrice: number;
  maxDonationAmount: number;
  currencyCode: string;
  finixMerchantId: string;
  finixEnvironment: FinixEnvironment;
  applePayMerchantDisplayName?: string;
  applePayPaymentId?: number;
  googlePayMerchantDisplayName?: string;
  googlePayGateway?: string;
  googlePayMerchantGatewayId?: string;
  googlePayMerchantId?: string;
  googlePayPaymentId?: number;
  availablePaymentMethodTypes: PaymentMethodType[];
}

export interface TransactionBillingDataDto {
  transactionId: number;
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  state?: string;
  postcode?: string;
  address: string;
  mobilePhone: string;
  transactionEmail?: string;
}

export interface ProviderInfoDto {
  id: number;
  type: string;
  enabled: boolean;
  comment: string;
  configReference: string;
}

export interface ProviderLimitDto {
  id: number;
  providerId: number;
  providerDescription: string;
  limitAmount: number;
  limitCurrency: string;
  adminNote: string;
  updatedBy: number;
  creationDate: string;
  updateDate: string;
}

export interface CreateProviderLimitDto {
  providerId: number;
  limitAmount: number;
  limitCurrency: string;
  adminNote?: string;
}

export interface UpdateProviderLimitDto {
  limitAmount: number;
  adminNote?: string;
}
