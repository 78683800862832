import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Label = styled.td`
  text-align: right;
  font-weight: bold;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Content = styled.td`
  text-align: left;
`;

interface Props extends PropsWithChildren {
  label: string;
}

const FormRow = ({ label, children }: Props) => {
  return (
    <tr>
      <Label>{label}</Label>
      <Content>{children}</Content>
    </tr>
  );
};

export default FormRow;
