import React from 'react';
import styled from 'styled-components';
import { ObjectType } from '../utils/dictionary';
import { usePopup } from '../../../providers/PopupProvider';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const PointerContainer = styled.div`
  cursor: pointer;
`;

const PopoverContainer = styled.div`
  margin: 20px;
  max-width: 400px;
`;

interface Props {
  reporters: number[];
  onReporterClick: (type: string, userId: number) => void;
}

const MAX_REPORTERS_IN_CELL = 3;

const ReportersCell = ({ reporters, onReporterClick }: Props) => {
  const { showPopover } = usePopup();
  const reportersShort =
    reporters.length > MAX_REPORTERS_IN_CELL && reporters.slice(0, MAX_REPORTERS_IN_CELL).join(' ') + ' ...';

  const renderReporters = () => {
    return (
      <Container>
        {reporters.map(reporter => (
          <PointerContainer key={reporter} onClick={() => onReporterClick(ObjectType.USER, reporter)}>
            {reporter}
          </PointerContainer>
        ))}
      </Container>
    );
  };
  return (
    <Container>
      {reportersShort ? (
        <PointerContainer
          onClick={e => showPopover(<PopoverContainer>{renderReporters()}</PopoverContainer>, e.currentTarget)}>
          {reportersShort}
        </PointerContainer>
      ) : (
        renderReporters()
      )}
    </Container>
  );
};

export default ReportersCell;
