import React, { useMemo } from 'react';
import { useGlobalData } from '../../../providers/GlobalDataProvider';

interface Props {
  adminId: number;
}

const UpdatedByCell = ({ adminId }: Props) => {
  const { supporters } = useGlobalData();

  const userInfo = useMemo(() => {
    const admin = supporters.find(supporter => supporter.id === adminId);
    return `[#${admin?.id}] ${admin?.firstName} ${admin?.lastName}`;
  }, [adminId, supporters]);

  return <>{userInfo}</>;
};

export default UpdatedByCell;
