import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ConversationWithDataDto,
  ConversationStatus,
  MessageDto,
  MessageInternalStatus,
  MessageType,
  ConversationDto,
} from '../../services/Message/messageService.dto';
import { websocketService } from '../../services/websocket/websocketService';
import { messageService } from '../../services/Message/messageService';
import { imageUploadService } from '../../services/utils/imageUploadService';
import { userService } from '../../services/User/userService';
import { useAuth } from '../AuthProvider';
import { useGlobalData } from '../GlobalDataProvider';
import { useError } from '../useError';
import { ConversationActionTypes, useConversationsReducer } from './conversations.reducer';
import { MessagesActionTypes, useMessagesReducer } from './messages.reducer';
import {
  MESSAGES_PATH,
  MESSAGES_TAB_ONGOING_PATH,
  MESSAGES_TAB_QUEUE_PATH,
  MESSAGES_TAB_SUPPORTED_PATH,
} from './messagesPaths';

interface ConversationsListData {
  conversations: ConversationWithDataDto[];
  fetchConversations: () => void;
  resetConversations: () => void;
  isLoading: boolean;
}

interface MessagesContextType {
  supportingConversations: ConversationsListData;
  waitingConversations: ConversationsListData;
  ongoingConversations: ConversationsListData;
  supportedConversations: ConversationsListData;
  conversation?: ConversationWithDataDto;
  selectConversation: (conversation?: ConversationWithDataDto, messageId?: number) => void;
  fetchConversation: (conversationId?: number) => void;
  conversationErrorMsg: string;
  messages: MessageDto[];
  selectedMessageId?: number;
  fetchMessagesBefore: (resetMessages: boolean, isLastMessage?: boolean) => void;
  fetchMessagesAfter: () => void;
  sendTextMessage: (content: string) => Promise<MessageDto>;
  sendPhotoMessage: (file: File) => Promise<MessageDto>;
  reopenConversation: () => void;
  transferConversation: (supporterId: number) => void;
  resolveConversation: () => void;
}

const MessagesContext = React.createContext<MessagesContextType | null>(null);

function MessagesProvider({ children }: any) {
  const { t } = useTranslation('messages');
  const { currentUser, updateUnreadMessagesCount } = useAuth();
  const { supporters } = useGlobalData();

  // TODO differentiate conversations when backend will be ready
  const supportingConversations = useConversation(ConversationStatus.ONGOING, currentUser?.id);
  const waitingConversations = useConversation(ConversationStatus.WAITING);
  const ongoingConversations = useConversation(ConversationStatus.ONGOING);
  const supportedConversations = useConversation(ConversationStatus.CLOSED);
  const { messagesState, dispatchMessages } = useMessagesReducer();
  const navigate = useNavigate();

  useEffect(() => {
    const listenerId = websocketService.registerNewMsgListener(processNewIncomingMessage);
    const confirmationListenerId = websocketService.registerMsgConfirmationListener(processMessageConfirmation);
    if (messagesState.selectedMessageId) fetchMessagesForSelectedId(messagesState.selectedMessageId);
    else fetchMessagesBefore(true, true);

    return () => {
      websocketService.unregisterNewMsgListener(listenerId);
      websocketService.unregisterMsgConfirmationListener(confirmationListenerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesState.conversation?.id, messagesState.selectedMessageId]);

  const fetchConversation = (conversationId?: number) => {
    if (messagesState.conversation) return;
    dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_REQUEST });
    if (conversationId) {
      messageService
        .fetchConversation(conversationId)
        .then(conversationResponse => {
          const conversation = conversationResponse.data;
          userService.fetchPublicUsers([conversation.endUserId]).then(usersResponse => {
            const user = usersResponse.data[0];
            dispatchMessages({
              type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_SUCCESS,
              payload: { conversation, user, supporters },
            });
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            dispatchMessages({
              type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_ERROR,
              payload: t('conversation-not-found-msg'),
            });
          } else {
            dispatchMessages({
              type: MessagesActionTypes.MESSAGES_FETCH_CONVERSATION_ERROR,
              payload: t('error.generic'),
            });
          }
        });
    }
  };

  const selectConversation = (conversation?: ConversationWithDataDto, messageId?: number) => {
    if (messagesState.conversation?.id !== conversation?.id || messagesState.selectedMessageId !== messageId) {
      dispatchMessages({
        type: MessagesActionTypes.MESSAGES_SELECT_CONVERSATION,
        payload: { conversation, messageId },
      });
    }
  };

  const transferConversation = (supporterId: number) => {
    if (!messagesState.conversation) return;
    navigate(MESSAGES_PATH + '/0', { replace: true });
    messageService
      .updateConversation(messagesState.conversation.id, ConversationStatus.ONGOING, supporterId)
      .then(() => changeTab(ConversationStatus.ONGOING, supporterId));
  };

  const resolveConversation = () => {
    if (!messagesState.conversation) return;
    navigate(MESSAGES_PATH + '/0', { replace: true });
    messageService.resolveConversation(messagesState.conversation.id).then(() => changeTab(ConversationStatus.ONGOING));
  };

  const reopenConversation = () => {
    if (!messagesState.conversation) return;
    navigate(MESSAGES_PATH + '/0', { replace: true });
    messageService
      .unResolveConversation(messagesState.conversation.id)
      .then(() => changeTab(ConversationStatus.ONGOING, currentUser?.id));
  };

  const changeTab = (status: string, supporterId?: number) => {
    const convId = messagesState.conversation?.id;
    if (status === ConversationStatus.WAITING) navigate(`${MESSAGES_TAB_QUEUE_PATH}/${convId}`, { replace: true });
    else if (status === ConversationStatus.CLOSED)
      navigate(`${MESSAGES_TAB_SUPPORTED_PATH}/${convId}`, { replace: true });
    else if (status === ConversationStatus.ONGOING) {
      if (supporterId === currentUser?.id) {
        navigate(`${MESSAGES_TAB_QUEUE_PATH}/${convId}`, { replace: true });
      } else {
        navigate(`${MESSAGES_TAB_ONGOING_PATH}/${convId}`, { replace: true });
      }
    }
  };

  const fetchAudit = async (conversationId: number) => {
    if (messagesState.auditMessages) return;
    messageService.fetchAudit(conversationId).then(response => {
      if (response.data.content) {
        dispatchMessages({
          type: MessagesActionTypes.MESSAGES_FETCH_AUDIT_SUCCESS,
          payload: {
            messageAuditDtos: response.data.content,
            supporters,
          },
        });
      }
    });
  };

  const fetchMessagesBefore = async (resetMessages: boolean, isLastMessage?: boolean) => {
    if (!messagesState.conversation) return;
    if (messagesState.isLoading) return;
    if (!messagesState.isFirstMessage || resetMessages) {
      const supportConversationId = messagesState.conversation.id;
      await fetchAudit(supportConversationId);
      dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_REQUEST, payload: { resetMessages } });
      const lastMessageId = resetMessages
        ? undefined
        : messagesState.messages.filter(message => message.type !== MessageType.AUDIT).slice(-1)[0]?.id;
      messageService
        .fetchMessagesBeforeId(messagesState.conversation.id, messagesState.pageSize, lastMessageId)
        .then(response => {
          dispatchMessages({
            type: MessagesActionTypes.MESSAGES_BEFORE_FETCH_SUCCESS,
            payload: { messages: response.data, isLastMessage },
          });
          if (response.data && messagesState.conversation) {
            confirmUnconfirmedMessages(response.data, messagesState.conversation);
            refreshMessageImages(response.data, messagesState.conversation.conversationId);
          }
        })
        .catch(() => {
          // TODO
          dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_ERROR, payload: t('error.generic') });
        });
    }
  };

  const fetchMessagesAfter = async () => {
    if (!messagesState.isLastMessage && !messagesState.isLoading && messagesState.conversation) {
      dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_REQUEST });
      const supportConversationId = messagesState.conversation.id;
      await fetchAudit(supportConversationId);
      const lastMessageId = messagesState.messages.filter(message => message.type !== MessageType.AUDIT)[0]?.id;
      messageService
        .fetchMessagesAfterId(supportConversationId, messagesState.pageSize, lastMessageId)
        .then(response => {
          dispatchMessages({ type: MessagesActionTypes.MESSAGES_AFTER_FETCH_SUCCESS, payload: response.data });
          if (response.data && messagesState.conversation) {
            confirmUnconfirmedMessages(response.data, messagesState.conversation);
            refreshMessageImages(response.data, messagesState.conversation.conversationId);
          }
        })
        .catch(() => {
          // TODO
          dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_ERROR, payload: t('error.generic') });
        });
    }
  };

  const fetchMessagesForSelectedId = async (messageId: number) => {
    if (!messagesState.isLoading && messagesState.conversation) {
      dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_REQUEST });

      try {
        const responseAfter = await messageService.fetchMessagesAfterId(
          messagesState.conversation.id,
          messagesState.pageSize,
          messageId
        );
        dispatchMessages({ type: MessagesActionTypes.MESSAGES_AFTER_FETCH_SUCCESS, payload: responseAfter.data });
        const responseBefore = await messageService.fetchMessagesBeforeId(
          messagesState.conversation.id,
          messagesState.pageSize,
          messageId,
          true
        );
        dispatchMessages({
          type: MessagesActionTypes.MESSAGES_BEFORE_FETCH_SUCCESS,
          payload: { messages: responseBefore.data },
        });

        const messages: MessageDto[] = [...responseAfter.data.reverse(), ...responseBefore.data];
        if (messages && messagesState.conversation) {
          confirmUnconfirmedMessages(messages, messagesState.conversation);
          refreshMessageImages(messages, messagesState.conversation.conversationId);
        }
      } catch (e) {
        dispatchMessages({ type: MessagesActionTypes.MESSAGES_FETCH_ERROR, payload: t('error.generic') });
      }
    }
  };

  const confirmUnconfirmedMessages = (msgList: MessageDto[], conversation: ConversationDto) => {
    if (conversation.supportEmployeeId !== currentUser?.id) return;
    const notConfirmedMessages = msgList
      .filter(
        message =>
          !message.deliveredToCurrentUser && message.id && message.internalStatus !== MessageInternalStatus.ERROR
      )
      .map(message => message.id);

    if (notConfirmedMessages.length > 0) {
      messageService
        .confirmMessages(notConfirmedMessages)
        .then(() => messageService.fetchConversation(conversation.id))
        .then(response => {
          waitingConversations.processConversationChanged(response.data);
          supportingConversations.processConversationChanged(response.data);
          ongoingConversations.processConversationChanged(response.data);
          updateUnreadMessagesCount();
        });
    }
  };

  const refreshMessageImages = (msgList: MessageDto[], conversationId: number) => {
    const expiredMessages = msgList
      .filter(
        (m: MessageDto) =>
          m.type === MessageType.PHOTO &&
          m.mediaUrlExpirationTime &&
          new Date().valueOf() > new Date(m.mediaUrlExpirationTime).valueOf()
      )
      .map((m: MessageDto) => m.id);

    if (expiredMessages.length > 0) {
      messageService.refreshMessageImages(expiredMessages, conversationId).then(response => {
        dispatchMessages({ type: MessagesActionTypes.MESSAGES_REFRESH_IMAGES, payload: response.data });
      });
    }
  };

  const processMessageConfirmation = (msgIds: number[]) => {
    dispatchMessages({ type: MessagesActionTypes.MESSAGES_CONFIRM, payload: msgIds });
  };

  const processNewIncomingMessage = (message: MessageDto) => {
    if (
      messagesState.conversation &&
      message.conversationId &&
      message.conversationId === messagesState.conversation.conversationId
    ) {
      dispatchMessages({ type: MessagesActionTypes.MESSAGES_ADD, payload: message });
      confirmUnconfirmedMessages([message], messagesState.conversation);
    }
  };

  async function sendTextMessage(content: string): Promise<MessageDto> {
    if (!messagesState.conversation) throw new Error('ConversationId cannot be empty');
    return messageService
      .addMessage(messagesState.conversation.conversationId, MessageType.TEXT, content)
      .then(response => {
        dispatchMessages({ type: MessagesActionTypes.MESSAGES_ADD, payload: response.data });
        supportingConversations.processNewOwnMessage(response.data);
        ongoingConversations.processNewOwnMessage(response.data);
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  }

  async function sendPhotoMessage(file: File): Promise<MessageDto> {
    if (!messagesState.conversation) throw new Error('ConversationId cannot be empty');
    const conversationId = messagesState.conversation.conversationId;
    let url: string;
    return messageService
      .generateMsgUrls(conversationId)
      .then(({ data }) => {
        const preSignedData = data[0];
        url = `${preSignedData.url}${preSignedData.fields.key}`;
        return imageUploadService.uploadImageFromFile(preSignedData, file);
      })
      .then(() => {
        return messageService.addMessage(conversationId, MessageType.PHOTO, url);
      })
      .then(response => {
        dispatchMessages({ type: MessagesActionTypes.MESSAGES_ADD, payload: response.data });
        supportingConversations.processNewOwnMessage(response.data);
        ongoingConversations.processNewOwnMessage(response.data);
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  }

  const messagesWithAudit = useMemo((): MessageDto[] => {
    if (!messagesState.auditMessages || messagesState.messages.length === 0) return messagesState.messages;
    const lastMsg = messagesState.messages[0];
    const firstMsg = messagesState.messages.slice(-1)[0];
    const filteredAuditMessages = messagesState.auditMessages.filter(auditMsg => {
      const conditionForFirstMsg: boolean = messagesState.isFirstMessage
        ? true
        : auditMsg.createdAt > firstMsg.createdAt;
      const conditionForLastMsg: boolean = messagesState.isLastMessage ? true : auditMsg.createdAt < lastMsg.createdAt;
      return conditionForFirstMsg && conditionForLastMsg;
    });
    return [...messagesState.messages, ...filteredAuditMessages].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }, [messagesState.messages, messagesState.auditMessages, messagesState.isLastMessage, messagesState.isFirstMessage]);

  return (
    <MessagesContext.Provider
      value={{
        supportingConversations,
        waitingConversations,
        ongoingConversations,
        supportedConversations,
        conversation: messagesState.conversation,
        selectConversation,
        fetchConversation,
        conversationErrorMsg: messagesState.conversationErrorMsg,
        messages: messagesWithAudit,
        selectedMessageId: messagesState.selectedMessageId,
        fetchMessagesBefore,
        fetchMessagesAfter,
        sendTextMessage,
        sendPhotoMessage,
        reopenConversation,
        transferConversation,
        resolveConversation,
      }}>
      {children}
    </MessagesContext.Provider>
  );
}

function useConversation(status: ConversationStatus, supporterEmployeeId?: number) {
  const { state, dispatch } = useConversationsReducer();
  const { supporters } = useGlobalData();
  const { handleError } = useError();

  useEffect(() => {
    const listenerId = websocketService.registerNewMsgListener(processNewIncomingMessage);
    return () => {
      websocketService.unregisterNewMsgListener(listenerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchConversations = () => {
    if (!state.isLastPage && !state.isLoading) {
      dispatch({ type: ConversationActionTypes.CONVERSATIONS_FETCH_REQUEST });
      messageService
        .fetchConversations(state.pageNumber, status, supporterEmployeeId)
        .then(response => response.data)
        .then(conversations => {
          const ids = conversations.content?.map(item => item.endUserId);
          if (ids) {
            userService.fetchPublicUsers(ids).then(response => {
              const users = response.data;
              dispatch({
                type: ConversationActionTypes.CONVERSATIONS_FETCH_SUCCESS,
                payload: { conversations, users, supporters },
              });
            });
          }
        })
        .catch(e => {
          handleError(e);
          dispatch({ type: ConversationActionTypes.CONVERSATIONS_FETCH_ERROR });
        });
    }
  };

  const processNewIncomingMessage = (message: MessageDto, conversation?: ConversationWithDataDto) => {
    // TODO handle situation when new conversation is started
    if (conversation) {
      processConversationChanged(conversation);
    }
  };

  const processConversationChanged = (conversation: ConversationWithDataDto) => {
    dispatch({ type: ConversationActionTypes.CONVERSATION_UPDATE, payload: conversation });
  };

  const processNewOwnMessage = (message: MessageDto) => {
    dispatch({ type: ConversationActionTypes.CONVERSATION_ADD_MESSAGE, payload: message });
  };

  const resetConversations = () => dispatch({ type: ConversationActionTypes.CONVERSATIONS_RESET });

  return {
    conversations: state.conversations,
    isLoading: state.isLoading,
    fetchConversations,
    processConversationChanged,
    processNewOwnMessage,
    resetConversations,
  };
}

const useMessages = () => {
  const messagesContext = useContext(MessagesContext);
  if (messagesContext == null) {
    throw new Error('useMessages() called outside of a MessagesProvider?');
  }
  return messagesContext;
};

export { MessagesProvider, useMessages };
