import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { Column } from '../../../../components/table/DataTable';
import { PaymentInfoDto } from '../../../../services/Payment/paymentService.dto';
import SwitchButton from '../../../../components/inputs/SwitchButton';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { usePayments } from '../hooks/usePayments';

const PaymentProvidersTable = () => {
  const { t } = useTranslation('payments');
  const { payments, setPaymentEnabled, isSaving } = usePayments();

  const columns: Column<PaymentInfoDto>[] = [
    {
      id: 'id',
      title: t('payment-providers.columns.id'),
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'providerType',
      title: t('payment-providers.columns.provider'),
      renderCell: data => data.providerType,
    },
    {
      id: 'providerId',
      title: t('payment-providers.columns.provider-id'),
      renderCell: data => data.providerId,
    },
    {
      id: 'type',
      title: t('payment-providers.columns.type'),
      renderCell: data => data.type,
    },
    {
      id: 'comment',
      title: t('payment-providers.columns.comment'),
      renderCell: data => data.comment,
    },
    {
      id: 'configReference',
      title: t('payment-providers.columns.config-reference'),
      renderCell: data => data.configReference,
    },
    {
      id: 'enabled',
      title: t('payment-providers.columns.enabled'),
      align: 'center',
      renderCell: data => <SwitchButton checked={data.enabled} onChange={value => setPaymentEnabled(data.id, value)} />,
    },
  ];

  const onTableStateChange = useCallback(() => {
    return Promise.resolve({ content: payments, totalElements: payments?.length });
  }, [payments]);

  if (isSaving) return <LoadingIndicator />;
  return <DataTable columns={columns} onTableStateChanged={onTableStateChange} disablePagination={true} />;
};

export default PaymentProvidersTable;
