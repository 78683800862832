import React, { useState } from 'react';
import styled from 'styled-components';
import ReactJson from 'react-json-view';
import { useTranslation } from 'react-i18next';
import { PartialMessageUpdateDto } from '../../../services/Maintenance/maintenanceService.dto';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';

const FormContainer = styled.div`
  padding: 10px;
  margin-top: 40px;
  position: relative;
  text-align: left;
  overflow: auto;
`;

interface Props {
  JSONString?: string;
  partialMessage?: PartialMessageUpdateDto;
  onConfirm?: (partialMessage: PartialMessageUpdateDto) => void;
}

const JSONPopover = (props: Props) => {
  const { t } = useTranslation();
  const [partialMessage, setPartialMessage] = useState<PartialMessageUpdateDto | undefined>(props.partialMessage);

  return (
    <FormContainer>
      <ReactJson
        src={partialMessage ? partialMessage : JSON.parse(props.JSONString || '')}
        name={false}
        displayDataTypes={false}
        onEdit={partialMessage ? edit => setPartialMessage(edit.updated_src as PartialMessageUpdateDto) : false}
      />
      {partialMessage && (
        <ConfirmButton label={t('confirm-btn')} onClick={() => props.onConfirm && props.onConfirm(partialMessage)} />
      )}
    </FormContainer>
  );
};

export default JSONPopover;
