import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../../components/inputs/SearchInput';
import { CampaignAdminDto, CampaignFilterDto } from '../../../../services/Campaign/campaignService.dto';
import { campaignService } from '../../../../services/Campaign/campaignService';
import { theme } from '../../../../assets/styles/theme';
import { TextMain } from '../../../../components/typography/Texts';
import { isNumeric } from '../../../../utils/textUtils';
import CampaignsList from './CampaignsList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: normal;
  margin: 0 5px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Icon = styled(ClearIcon)`
  cursor: pointer;
  color: ${theme.color.red};
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
`;

interface Props {
  campaign?: CampaignAdminDto;
  onCampaignSelected: (campaign?: CampaignAdminDto) => void;
  ownerId?: number;
}

const CampaignSelector = ({ campaign, onCampaignSelected, ownerId }: Props) => {
  const { t } = useTranslation('campaigns');
  const [searchValue, setSearchValue] = useState('');
  const [campaigns, setCampaigns] = useState<CampaignAdminDto[] | undefined>(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignAdminDto | undefined>(campaign);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleCampaignSelected = (campaign?: CampaignAdminDto) => {
    setSelectedCampaign(campaign);
    onCampaignSelected(campaign);
  };

  useEffect(() => {
    if (!isSearchFocused) {
      setCampaigns(undefined);
    } else if (!searchValue && !ownerId) {
      setCampaigns(undefined);
    } else {
      const filter: CampaignFilterDto = {
        page: 0,
        size: 10,
        sort: 'created,desc',
        name: isNumeric(searchValue) ? undefined : searchValue,
        id: isNumeric(searchValue) ? Number.parseInt(searchValue) : undefined,
        status: 'ACTIVE',
        ownerId: ownerId,
      };
      campaignService
        .fetchCampaigns(filter)
        .then(response =>
          setCampaigns(response.data.content?.filter(campaign => !campaign.isHidden && !campaign.isPending) || [])
        );
    }
  }, [searchValue, ownerId, isSearchFocused]);

  useEffect(() => setSelectedCampaign(campaign), [campaign]);

  if (selectedCampaign) {
    const imgUrl =
      selectedCampaign.imageUrls && selectedCampaign.imageUrls.length > 0 ? selectedCampaign.imageUrls[0] : '';
    return (
      <Container>
        <SelectedContainer>
          <Image src={imgUrl} />
          <TextMain>{`${selectedCampaign.name} (#${selectedCampaign.id})`}</TextMain>
          <Icon style={{ fontSize: '16px' }} onClick={() => handleCampaignSelected(undefined)} />
        </SelectedContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <SearchInput
          onChange={setSearchValue}
          value={searchValue}
          placeholder={t('search-placeholder')}
          onFocusChange={setIsSearchFocused}
        />
        <CampaignsList campaigns={campaigns} onCampaignSelected={handleCampaignSelected} />
      </Container>
    );
  }
};

export default CampaignSelector;
