import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { CardHeader, Grid } from '../../../../components/cards/CardCommons';
import CardParamInfo from '../../../../components/cards/CardParamInfo';
import UserDetailsCard from '../../../users/components/UserDetailsCard';
import { useTransactionBillingData } from '../hooks/useTransactionBillingData';

export const Container = styled.div`
  width: 600px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

interface Props {
  transactionId: number;
  buyerId?: number;
}

const TransactionBillingDataCard = ({ transactionId, buyerId }: Props) => {
  const { t } = useTranslation('payments');
  const { transactionBillingData, isLoading } = useTransactionBillingData(transactionId);

  if (!transactionBillingData || isLoading)
    return (
      <Container>
        <LoadingIndicator />
      </Container>
    );

  const { firstName, lastName, address, city, state, postcode, country, mobilePhone, transactionEmail } =
    transactionBillingData;
  const addressDetails = `${address}\n${city}, ${state} ${postcode}`;

  return (
    <Container>
      <CardHeader>{t('transactions.billing-data.header', { transactionId })}</CardHeader>
      <Grid>
        <CardParamInfo label={t('transactions.billing-data.name')} value={`${firstName} ${lastName}`} />
        <CardParamInfo
          label={t('transactions.billing-data.buyer-id')}
          value={`#${buyerId}`}
          popover={<UserDetailsCard userId={buyerId} />}
        />
        <CardParamInfo label={t('transactions.billing-data.address')} value={addressDetails} />
        <CardParamInfo label={t('transactions.billing-data.country')} value={country} />
        <CardParamInfo label={t('transactions.billing-data.phone')} value={mobilePhone} />
        <CardParamInfo label={t('transactions.billing-data.email')} value={transactionEmail} />
      </Grid>
    </Container>
  );
};

export default TransactionBillingDataCard;
