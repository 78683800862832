import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';
import { SectionHeader } from '../typography/Headers';
import RectangularButton from '../buttons/RectangularButton';
import { TextMain } from '../typography/Texts';

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FormHeader = styled(SectionHeader)`
  text-align: center;
  margin: 10px;
`;

export const FormTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  border: 2px solid ${theme.color.lighterGray};
  overflow-y: auto;

  & tr {
    border-bottom: 2px solid ${theme.color.lighterGray};
    height: 55px;
  }
  & tr:last-child {
    border-bottom: none;
  }
  & td {
    padding: 0 10px;
  }
  & td:last-child {
    width: 80%;
    border-left: 2px solid ${theme.color.lighterGray};
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  margin-bottom: 45px;
  width: 100%;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const SaveButton = styled(RectangularButton)`
  width: 50px;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px;
`;

export const ErrorMessages = styled.div`
  width: 480px;
  min-height: 40px;
  max-height: 800px;
  border-radius: 10px;
  align-items: flex-start;
  border: 2px solid ${theme.color.darkGray};
  background-color: ${theme.color.white};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  padding: 10px;

  ${SaveButton}:hover + & {
    display: flex;
    flex-direction: column;
  }
`;

export const ErrorMessage = styled(TextMain)`
  color: ${theme.color.red};
`;
