import React from 'react';
import styled from 'styled-components';
import { TextSmall } from '../../../../components/typography/Texts';
import { theme } from '../../../../assets/styles/theme';
import { CampaignAdminDto } from '../../../../services/Campaign/campaignService.dto';
import { convertToThumbnail300 } from '../../../../utils/thumbnails';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${theme.color.lightGray};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
`;

interface Props {
  campaign: CampaignAdminDto;
  onClick: () => void;
}

const CampaignsListItem = ({ campaign, onClick }: Props) => {
  const imgUrl = campaign.imageUrls && campaign.imageUrls.length > 0 ? campaign.imageUrls[0] : '';
  return (
    <Container onMouseDown={onClick}>
      <Image src={convertToThumbnail300(imgUrl)} />
      <InfoContainer>
        <TextSmall>
          [#{campaign.id}] {campaign.name}
        </TextSmall>
      </InfoContainer>
    </Container>
  );
};

export default CampaignsListItem;
