import { TableFilterStateDto } from '../../../components/table/DataTable';
import {
  CampaignCategoryDto,
  CampaignFilterDto,
  EditCampaignDto,
  NewCampaignDto,
} from '../../../services/Campaign/campaignService.dto';
import { CampaignFormData } from '../hooks/CampaignContext';
import { parseMultiChoice } from '../../../components/table/filterConverterUtils';

export const convertFiltersToCampaignFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>,
  categories: CampaignCategoryDto[]
): CampaignFilterDto => {
  const categoryValue = filter.filtersMap?.get('category');
  const categoryKey = categories.find(category => category.displayName === categoryValue)?.code;

  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    id: filter.filtersMap?.get('id'),
    name: filter.filtersMap?.get('name'),
    description: filter.filtersMap?.get('description'),
    category: categoryKey,
    ownerId: filter.filtersMap?.get('ownerId'),
    status: parseMultiChoice(filter, 'status'),
  };
};

export const convertFormDataToNewCampaignDto = (formData: CampaignFormData): NewCampaignDto => {
  const imgUrls = formData.photos ? formData.photos.filter(photo => photo.safe).map(photo => photo.url || '') : [];
  return {
    categories: formData.categories?.map(category => category.code) || [],
    description: formData.description,
    imageUrls: imgUrls,
    videoUrl: !!formData.videoUrl ? formData.videoUrl : undefined,
    campaignLocation: formData.campaignLocation,
    moneyGoal: formData.goal ? formData.goal : undefined,
    name: formData.name || '',
    timelineFrom: formData.timelineFrom ? formData.timelineFrom.toISOString() : '',
    timelineTo: formData.timelineTo ? formData.timelineTo.toISOString() : undefined,
  };
};

export const convertFormDataToEditCampaignDto = (formData: CampaignFormData, campaignId: number): EditCampaignDto => {
  return { ...convertFormDataToNewCampaignDto(formData), id: campaignId };
};
