import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ManualRefundDto } from '../../../../services/Payment/paymentService.dto';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

const ButtonContainer = styled.div`
  width: 20px;
  margin: 2px;
`;

interface Props {
  refund: ManualRefundDto;
  updateStatus: (user: ManualRefundDto) => void;
}

const ManualRefundsActions = ({ refund, updateStatus }: Props) => {
  const { t } = useTranslation('payments');

  const canUpdateStatus = refund.status === 'TODO' || refund.status === 'IN_PROGRESS';

  return (
    <Container>
      <ButtonContainer title={t('manual-refunds.update-status-tooltip')}>
        {canUpdateStatus && <EditIcon style={iconStyle} onClick={() => updateStatus(refund)} />}
      </ButtonContainer>
    </Container>
  );
};

export default ManualRefundsActions;
