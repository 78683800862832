import { usePopup } from '../../../../providers/PopupProvider';
import {
  CreateProviderLimitDto,
  ProviderLimitDto,
  UpdateProviderLimitDto,
} from '../../../../services/Payment/paymentService.dto';
import AddProviderLimitForm from '../components/AddProviderLimitForm';
import UpdateProviderLimitForm from '../components/UpdateProviderLimitForm';

export const useProviderLimitsPopovers = () => {
  const { showPopover, hidePopover } = usePopup();

  const showCreateProviderLimitPopover = (onConfirm: (request: CreateProviderLimitDto) => void) => {
    const handleConfirm = (request: CreateProviderLimitDto) => {
      hidePopover();
      onConfirm(request);
    };
    showPopover(<AddProviderLimitForm onConfirmPress={handleConfirm} />);
  };

  const showUpdateProviderLimitPopover = (
    providerLimit: ProviderLimitDto,
    onConfirm: (id: number, request: UpdateProviderLimitDto) => void
  ) => {
    const handleConfirm = (request: UpdateProviderLimitDto) => {
      hidePopover();
      onConfirm(providerLimit.id, request);
    };
    showPopover(<UpdateProviderLimitForm providerLimit={providerLimit} onConfirmPress={handleConfirm} />);
  };

  return { showCreateProviderLimitPopover, showUpdateProviderLimitPopover };
};
