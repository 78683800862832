import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemContext, ItemContextProps, useItemForm } from '../../hooks/ItemContext';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import {
  ErrorMessage,
  ErrorMessages,
  FormContainer,
  FormHeader,
  FormTable,
  LoadingOverlay,
  SaveButton,
  TableWrapper,
} from '../../../../components/forms/Containers';
import UserInputRow from './UserInputRow';
import TypeInputRow from './TypeInputRow';
import SellingMethodInputRow from './SellingMethodInputRow';
import ConditionInputRow from './ConditionInputRow';
import PriceInputRow from './PriceInputRow';
import CategoryInputRow from './CategoryInputRow';
import DeliveryInputRow from './DeliveryInputRow';
import LocationInputRow from './LocationInputRow';
import QuantityInputRow from './QuantityInputRow';
import TitleInputRow from './TitleInputRow';
import DescriptionInputRow from './DescriptionInputRow';
import CampaignInputRow from './CampaignInputRow';
import AuctionEndDateInputRow from './AuctionEndDateInputRow';
import PhotosInputRow from './PhotosInputRow';
import VideoUrlInputRow from './VideoUrlInputRow';

const ItemForm = () => {
  const { t } = useTranslation('items');
  const { itemId, errorMessages, isLoading, saveItem } = useItemForm();

  return (
    <FormContainer>
      <FormHeader>{!itemId ? t('add-item.header-add') : t('add-item.header-edit', { itemId })}</FormHeader>
      {isLoading && (
        <LoadingOverlay>
          <LoadingIndicator />{' '}
        </LoadingOverlay>
      )}
      <TableWrapper>
        <FormTable>
          <tbody>
            <UserInputRow />
            <TypeInputRow />
            <SellingMethodInputRow />
            <TitleInputRow />
            <DescriptionInputRow />
            <ConditionInputRow />
            <CategoryInputRow />
            <DeliveryInputRow />
            <LocationInputRow />
            <PriceInputRow />
            <AuctionEndDateInputRow />
            <QuantityInputRow />
            <CampaignInputRow />
            <PhotosInputRow />
            <VideoUrlInputRow />
          </tbody>
        </FormTable>
      </TableWrapper>

      <SaveButton label={t('common:save')} onClick={saveItem} disabled={errorMessages.length > 0} />
      {errorMessages.length > 0 && (
        <ErrorMessages>
          {errorMessages.map(msg => (
            <ErrorMessage key={msg}>{msg}</ErrorMessage>
          ))}
        </ErrorMessages>
      )}
    </FormContainer>
  );
};

const ItemFormWithContext = ({ itemId, onItemSaved }: ItemContextProps) => {
  return (
    <ItemContext itemId={itemId} onItemSaved={onItemSaved}>
      <ItemForm />
    </ItemContext>
  );
};

export default ItemFormWithContext;
