import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  onConfirmPress: () => void;
}

const CancelOrderPopover = ({ onConfirmPress }: Props) => {
  const { t } = useTranslation('items');

  return (
    <FormContainer>
      <FormHeader>{t('auctions.new-winner.cancel-order.menu-title')}</FormHeader>
      <FormHeader>{t('auctions.new-winner.cancel-order.menu-subtitle')}</FormHeader>
      <ConfirmButton label={t('auctions.new-winner.cancel-order.button-text')} onClick={onConfirmPress} />
    </FormContainer>
  );
};

export default CancelOrderPopover;
