import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../../components/typography/PopoverTypography';
import {
  MANUAL_REFUND_OPTIONS,
  ManualRefundDto,
  ManualRefundStatus,
} from '../../../../services/Payment/paymentService.dto';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  manualRefund: ManualRefundDto;
  onConfirmPress: (id: number, notes: string, status: ManualRefundStatus) => void;
}

const ManualRefundsUpdateDialog = ({ manualRefund, onConfirmPress }: Props) => {
  const { t } = useTranslation('payments');

  const [notes, setNotes] = useState(manualRefund.notes || '');
  const [status, setStatus] = useState<ManualRefundStatus>(manualRefund.status);

  return (
    <FormContainer>
      <FormHeader>
        {t('manual-refunds.update-status-popover.title')} <b>{`${manualRefund.orderId}`} </b>
      </FormHeader>

      <DropdownWithLabel
        label={t('manual-refunds.update-status-popover.status')}
        value={status}
        onChange={value => setStatus(value as ManualRefundStatus)}
        options={[...MANUAL_REFUND_OPTIONS]}
        placeholder={t('manual-refunds.update-status-popover.status-placeholder')}
        minWidth={300}
      />

      <InputWithLabel
        label={t('manual-refunds.update-status-popover.notes')}
        onChange={setNotes}
        value={notes}
        multiline={true}
        minWidth={300}
      />

      <ConfirmButton
        label={t('manual-refunds.update-status-popover.confirm')}
        onClick={() => onConfirmPress(manualRefund.id, notes, status)}
        disabled={!notes || !status}
      />
    </FormContainer>
  );
};

export default ManualRefundsUpdateDialog;
