import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { CSSProperties, useEffect, useState } from 'react';
import { FormHeader, FormTable } from '../../../components/forms/Containers';
import FormRow from '../../../components/forms/FormRow';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import PhotosInput from '../../../components/forms/PhotosInput';
import { useUploadImages } from '../../../services/Image/useUploadImages';
import { UploadContext } from '../../../services/utils/imageUploadService';
import CampaignSelector from '../../campaigns/components/campaignSelector/CampaignSelector';
import { useCampaignDetails } from '../../campaigns/hooks/useCampaignDetails';
import RectangularButton from '../../../components/buttons/RectangularButton';
import { CancelButton } from '../../../components/buttons/popoverButtons';
import { FundraiserDetailsDto, FundraiserPageUpdateDto } from '../../../services/User/userService.dto';
import DeleteButton from '../../../components/buttons/DeleteButton';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0 5px;
`;

const BottomSection = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  fundraiser: FundraiserDetailsDto;
  onSave: (fundraiserPageUpdateDto: FundraiserPageUpdateDto) => void;
  onCancel: () => void;
  onDelete: () => void;
}

const imgStyle: CSSProperties = {
  aspectRatio: 2.5,
  width: 150,
  objectFit: 'contain',
};

const ALLOWED_SUBDOMAIN_CHARACTERS_REGEX = /^(?!.*--)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;
const MAX_LOGO_SIZE_KB = 1000;

const UpdateFundraiserPageForm = (props: Props) => {
  const { fundraiser, onSave, onCancel, onDelete } = props;
  const { t } = useTranslation('users');

  const { campaign: currentCampaign } = useCampaignDetails(fundraiser.defaultDisplayedCampaignId);

  const [firstName, setFirstName] = useState(fundraiser.firstName);
  const [lastName, setLastName] = useState(fundraiser.lastName);
  const [about, setAbout] = useState(fundraiser.about || '');
  const [subdomainName, setSubdomainName] = useState(fundraiser.subdomainName || '');
  const [campaign, setCampaign] = useState(currentCampaign);

  const { photos, addPhoto, deletePhoto } = useUploadImages(
    UploadContext.USER,
    !!fundraiser.logoUrl ? [fundraiser.logoUrl] : [],
    MAX_LOGO_SIZE_KB
  );

  useEffect(() => currentCampaign && setCampaign(currentCampaign), [currentCampaign]);

  const isValid = !!subdomainName && ALLOWED_SUBDOMAIN_CHARACTERS_REGEX.test(subdomainName) && !!firstName;

  const onSavePress = () => {
    const updateDto: FundraiserPageUpdateDto = {
      firstName: firstName,
      lastName: lastName,
      about: about,
      subdomainName: subdomainName,
      logoUrl: photos.length > 0 ? photos[0].url : undefined,
      defaultCampaignId: campaign?.id,
    };
    onSave(updateDto);
  };

  return (
    <FormContainer>
      <FormHeader>{t('fundraiser-page.header')}</FormHeader>
      <FormTable>
        <tbody>
          <FormRow label={t('fundraiser-page.name')}>
            <NameContainer>
              <StyledInput label={''} onChange={setFirstName} value={firstName} />
              <StyledInput label={''} onChange={setLastName} value={lastName} />
            </NameContainer>
          </FormRow>
          <FormRow label={t('fundraiser-page.about')}>
            <StyledInput label={''} onChange={setAbout} value={about} multiline={true} rows={1} />
          </FormRow>
          <FormRow label={t('fundraiser-page.subdomain-name')}>
            <StyledInput label={''} onChange={setSubdomainName} value={subdomainName} />
          </FormRow>
          <FormRow label={t('fundraiser-page.default-campaign')}>
            <CampaignSelector campaign={campaign} onCampaignSelected={setCampaign} ownerId={fundraiser.id} />
          </FormRow>
          <FormRow label={t('fundraiser-page.logo')}>
            <PhotosInput
              photos={photos}
              addPhoto={addPhoto}
              deletePhoto={deletePhoto}
              limit={1}
              previewImageStyle={imgStyle}
            />
          </FormRow>
        </tbody>
      </FormTable>
      <BottomSection>
        <DeleteButton onClick={onDelete} disabled={!fundraiser.subdomainName} />
        <ButtonsSection>
          <CancelButton label={t('common:cancel')} onClick={onCancel} />
          <RectangularButton label={t('common:save')} onClick={onSavePress} disabled={!isValid} />
        </ButtonsSection>
      </BottomSection>
    </FormContainer>
  );
};

export default UpdateFundraiserPageForm;
