import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 320px;
`;

interface Props {
  userData: { firstName: string; lastName: string };
  onUnblockPressed: () => void;
}

const UnblockUserPopover = (props: Props) => {
  const { t } = useTranslation('users');
  return (
    <FormContainer>
      <FormHeader>
        {t('unblock-label')} <b>{`${props.userData.firstName} ${props.userData.lastName}`}</b>
      </FormHeader>
      <ConfirmButton label={t('unblock-btn')} onClick={props.onUnblockPressed} />
    </FormContainer>
  );
};

export default UnblockUserPopover;
