import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useItemForm } from '../../hooks/ItemContext';
import PriceInput from '../../../../components/inputs/PriceInput';
import FormRow from '../../../../components/forms/FormRow';
import { CURRENCIES } from '../../../../services/Payment/paymentService.dto';

const Container = styled.div`
  display: flex;
  padding-right: 5px;
`;

const PriceInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setPrice, setCurrency, setNegotiable, auction } = useItemForm();
  const label = formData.sellingMethod === 'AUCTION' ? t('add-item.starting-price') : t('add-item.price');
  return (
    <FormRow label={label}>
      <Container>
        <PriceInput
          price={formData.price || 0}
          currency={formData.currency}
          negotiable={formData.negotiable}
          onPriceChange={setPrice}
          onCurrencyChange={setCurrency}
          onNegotiableChange={setNegotiable}
          canBeNegotiable={formData.sellingMethod === 'STANDARD'}
          availableCurrencies={CURRENCIES}
          disabled={auction && auction.bids.length > 0}
        />
      </Container>
    </FormRow>
  );
};

export default PriceInputRow;
