import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import { FundraiserDetailsDto } from '../../../services/User/userService.dto';

const Container = styled.div`
  display: flex;
  padding-right: 20px;
  position: relative;
`;

const EditButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

interface Props {
  fundraiser: FundraiserDetailsDto;
  onEditPressed: () => void;
}

const SubdomainCell = ({ fundraiser, onEditPressed }: Props) => {
  return (
    <Container>
      {fundraiser.subdomainName}
      {fundraiser.fundraiserVerificationCompleted && (
        <EditButton onClick={onEditPressed}>
          <EditIcon style={{ fontSize: 20 }} />
        </EditButton>
      )}
    </Container>
  );
};

export default SubdomainCell;
