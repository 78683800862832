import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers/AuthProvider';
import ProfilePicture from '../image/ProfilePicture';
import { TextMain } from '../typography/Texts';
import RectangularButton from '../buttons/RectangularButton';
import { usePopup } from '../../providers/PopupProvider';

const Container = styled.div`
  cursor: pointer;
`;

const ProfileInfoContainer = styled.div`
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const UserName = styled(TextMain)`
  font-weight: bold;
`;

const LogoutButton = styled(RectangularButton)`
  max-width: 50px;
  padding: 5px;
  font-weight: normal;
  margin-top: 10px;
`;

const MyProfile = () => {
  const { currentUser, logout } = useAuth();
  const { t } = useTranslation('account');
  const { showPopover, hidePopover } = usePopup();

  const renderProfileInfo = () => {
    return (
      <ProfileInfoContainer>
        <UserName>
          {currentUser?.firstName} {currentUser?.lastName}
        </UserName>
        <LogoutButton
          label={t('my-profile.logout')}
          onClick={() => {
            logout();
            hidePopover();
          }}
        />
      </ProfileInfoContainer>
    );
  };

  if (!currentUser) return <></>;
  return (
    <Container onClick={e => showPopover(renderProfileInfo(), e.currentTarget)}>
      <ProfilePicture imageUrl={currentUser?.imageUrl} />
    </Container>
  );
};

export default MyProfile;
