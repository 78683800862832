import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const EditButton = styled(EditIcon)`
  cursor: pointer;
`;

const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
`;

interface Props {
  onUpdateClick: () => void;
  onDeleteClick: () => void;
}

const ProviderLimitsActionsCell = ({ onUpdateClick, onDeleteClick }: Props) => {
  return (
    <Container>
      <EditButton style={{ fontSize: 18 }} onClick={onUpdateClick} />
      <DeleteButton style={{ fontSize: 18 }} onClick={onDeleteClick} />
    </Container>
  );
};

export default ProviderLimitsActionsCell;
