import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../../components/containers/PageContainer';
import { theme } from '../../../assets/styles/theme';
import PaymentProvidersTable from './components/PaymentProvidersTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
  max-width: 1200px;
`;

const PaymentProviders = () => {
  return (
    <PageContainer>
      <Container>
        <PaymentProvidersTable />
      </Container>
    </PageContainer>
  );
};

export default PaymentProviders;
