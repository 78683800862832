import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../components/containers/PageContainer';
import { theme } from '../../assets/styles/theme';
import { DataTableRef } from '../../components/table/dataTableRef';
import DataTable, { Column, TableFilterStateDto } from '../../components/table/DataTable';
import { AuctionDto, AuctionStatus, BidDto } from '../../services/Auction/auctionService.dto';
import { auctionService } from '../../services/Auction/auctionService';
import { dateUtils } from '../../utils/dateUtils';
import { SectionHeader } from '../../components/typography/Headers';
import { userService } from '../../services/User/userService';
import { UserPublicDto } from '../../services/User/userService.dto';
import { orderService } from '../../services/Order/orderService';
import CenteredCellWithEdit from '../../components/table/CenteredCellWithEdit';
import { itemService } from '../../services/Item/itemService';
import { ItemPublicDto } from '../../services/Item/itemService.dto';
import { useError } from '../../providers/useError';
import { usePopup } from '../../providers/PopupProvider';
import ChooseNewWinnerPopover from './components/auctions/ChooseNewWinnerPopover';
import CancelOrderPopover from './components/auctions/CancelOrderPopover';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Title = styled(SectionHeader)`
  margin: 20px 0;
`;

interface Props {
  auctionStatuses?: AuctionStatus[];
  title: string;
}

type AuctionOwner = {
  auctionId: number;
  owner?: UserPublicDto;
};

const BIDDER_EDITABLE_AUCTION_STATUSES = ['AWAITING_PAYMENT', 'CANCELED'];

const Auctions = (props: Props) => {
  const [bidders, setBidders] = useState<UserPublicDto[]>([]);
  const [owners, setOwners] = useState<AuctionOwner[]>([]);
  const navigate = useNavigate();
  const { showPopover, hidePopover } = usePopup();

  const dataTableRef = useRef<DataTableRef>(null);
  const { t } = useTranslation('items');
  const { handleError } = useError();

  const extractWinningBid = (auction: AuctionDto): BidDto | undefined => {
    return auction.bids.find(bid => bid.id === auction.winningBidId);
  };

  const extractUser = (user?: UserPublicDto): string | undefined => {
    return user ? `${user.firstName} ${user.lastName || ''} (${t('auctions.user-id')}: ${user.id})` : '';
  };

  const extractBidder = (bid?: BidDto): string | undefined => {
    if (!bid) return undefined;
    const bidder = bidders.find(bidder => bidder.id === bid.userId);
    return extractUser(bidder);
  };

  const extractOwner = (auctionId: number): string | undefined => {
    const owner = owners.find(auctionOwner => auctionOwner.auctionId === auctionId)?.owner;
    return extractUser(owner);
  };

  const handleCurrentWinningBidderEdit = async (anchor: HTMLDivElement, auction: AuctionDto) => {
    const winningBid = extractWinningBid(auction);
    if (!winningBid) return undefined;
    const confirmedOrders = await orderService.fetchConfirmedOrders(auction.itemId, winningBid.userId);
    const order = confirmedOrders.data.content?.[0];
    if (order) {
      showPopover(<CancelOrderPopover onConfirmPress={() => handleCancelOrderConfirmPress(order.id)} />, anchor);
    } else {
      showPopover(<ChooseNewWinnerPopover auction={auction} onConfirmPress={handleChangeWinnerPopupConfirm} />, anchor);
    }
  };

  const handleCancelOrderConfirmPress = (orderId: number) => {
    navigate(`/orders/list/${orderId}`);
    hidePopover();
  };

  const handleChangeWinnerPopupConfirm = (auctionId: number, bidId: number) => {
    auctionService
      .updateWinningBid(auctionId, bidId)
      .then(() => hidePopover())
      .catch(e => handleError(e));
  };

  const columns: Column<AuctionDto>[] = [
    {
      id: 'id',
      title: t('auctions.columns.id'),
      isSortable: true,
      minWidth: 40,
      maxWidth: 40,
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'itemId',
      title: t('auctions.columns.item-id'),
      isSortable: true,
      minWidth: 50,
      maxWidth: 70,
      align: 'center',
      renderCell: data => data.itemId,
    },
    {
      id: 'owner',
      title: t('auctions.columns.owner'),
      isSortable: false,
      minWidth: 100,
      maxWidth: 400,
      align: 'center',
      renderCell: data => extractOwner(data.id),
    },
    {
      id: 'currency',
      title: t('auctions.columns.currency'),
      minWidth: 50,
      maxWidth: 70,
      align: 'center',
      renderCell: data => data.currency,
    },
    {
      id: 'currentBidder',
      title: t('auctions.columns.current-bidder'),
      minWidth: 100,
      maxWidth: 400,
      align: 'center',
      renderCell: data =>
        BIDDER_EDITABLE_AUCTION_STATUSES.includes(data.status) && data.bids.length > 0 ? (
          <CenteredCellWithEdit onClick={element => handleCurrentWinningBidderEdit(element, data)}>
            {extractBidder(extractWinningBid(data))}
          </CenteredCellWithEdit>
        ) : (
          extractBidder(extractWinningBid(data))
        ),
    },
    {
      id: 'currentBid',
      title: t('auctions.columns.current-bid'),
      minWidth: 50,
      maxWidth: 70,
      align: 'center',
      renderCell: data => extractWinningBid(data)?.amount,
    },
    {
      id: 'status',
      title: t('auctions.columns.status'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => data.status,
    },
    {
      id: 'startDateTime',
      title: t('auctions.columns.start-date-time'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => dateUtils.formatYearMonthDay(new Date(data.startDateTime)),
    },
    {
      id: 'endDateTime',
      title: t('auctions.columns.end-date-time'),
      isSortable: true,
      minWidth: 70,
      maxWidth: 100,
      align: 'center',
      renderCell: data => dateUtils.formatYearMonthDay(new Date(data.endDateTime)),
    },
  ];

  const convertFilters = (filter: TableFilterStateDto<any>) => ({
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy && `${filter.sortBy},${filter.sortDirection}`,
  });

  const fetchAuctionBidders = async (auctions: AuctionDto[]) => {
    const winningBidderIds = auctions
      .map(auction => extractWinningBid(auction)?.userId)
      .filter(userId => !!userId) as number[];

    if (winningBidderIds.length > 0) {
      await userService.fetchPublicUsers(winningBidderIds).then(response => setBidders(response.data));
    }
  };

  const fetchAuctionOwners = async (auctions: AuctionDto[]) => {
    const auctionItemIds = auctions.map(auction => auction.itemId);

    const itemDetailsResponse = await itemService.fetchPublicItems(auctionItemIds);
    const itemDetails: Record<number, ItemPublicDto> = Object.fromEntries(
      itemDetailsResponse.data.map(item => [item.id, item])
    );

    const ownerIds = auctionItemIds
      .map(itemId => itemDetails[itemId]?.ownerId)
      .filter(ownerId => ownerId !== undefined);

    if (ownerIds.length > 0) {
      const ownersResponse = await userService.fetchPublicUsers(ownerIds);
      const auctionOwners: AuctionOwner[] = auctions
        .map(auction => ({
          auctionId: auction.id,
          owner: ownersResponse.data.find(owner => owner.id === itemDetails[auction.itemId]?.ownerId),
        }))
        .filter(auctionOwner => auctionOwner.owner);

      setOwners(auctionOwners);
    }
  };

  const handleTableStateChange = useCallback(async (state: TableFilterStateDto<any>) => {
    const auctionFilter = convertFilters(state);

    const auctionsResponse = await auctionService.fetchAuctions(auctionFilter, props.auctionStatuses);

    if (auctionsResponse.data.content?.length) {
      const auctions = auctionsResponse.data.content;

      try {
        await fetchAuctionBidders(auctions);
        await fetchAuctionOwners(auctions);
      } catch (error: any) {
        handleError(error);
      }
    }

    return auctionsResponse.data;
  }, []);

  return (
    <PageContainer>
      <Container>
        <Title>{props.title}</Title>
        <DataTable ref={dataTableRef} columns={columns} onTableStateChanged={handleTableStateChange} />
      </Container>
    </PageContainer>
  );
};

export default Auctions;
