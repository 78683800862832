import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../components/typography/PopoverTypography';
import DropdownWithLabel from '../../../components/inputs/DropdownWithLabel';
import {
  REFUND_REQUEST_STATUSES,
  RefundRequestDto,
  RefundRequestStatus,
} from '../../../services/Order/orderService.dto';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  refundRequest: RefundRequestDto;
  onConfirmPress: (notes: string, status: RefundRequestStatus) => void;
}

const RefundRequestUpdateForm = ({ refundRequest, onConfirmPress }: Props) => {
  const { t } = useTranslation('orders');

  const [notes, setNotes] = useState(refundRequest.notes || '');
  const [status, setStatus] = useState<RefundRequestStatus>(refundRequest.status);

  return (
    <FormContainer>
      <FormHeader>
        {t('update-refund-request-dialog.header')} <b>{`${refundRequest.orderId}`} </b>
      </FormHeader>

      <DropdownWithLabel
        label={t('update-refund-request-dialog.status')}
        value={status}
        onChange={value => setStatus(value as RefundRequestStatus)}
        options={[...REFUND_REQUEST_STATUSES]}
        hideEmptyOption={true}
        minWidth={300}
      />

      <InputWithLabel
        label={t('update-refund-request-dialog.notes')}
        onChange={setNotes}
        value={notes}
        multiline={true}
        minWidth={300}
      />

      <ConfirmButton
        label={t('update-refund-request-dialog.confirm-btn')}
        onClick={() => onConfirmPress(notes, status)}
        disabled={!notes && status === refundRequest.status}
      />
    </FormContainer>
  );
};

export default RefundRequestUpdateForm;
