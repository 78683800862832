import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../../components/inputs/DropdownWithLabel';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';
import { FormHeader } from '../../../components/typography/PopoverTypography';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 320px;
`;

interface Props {
  userData: { firstName: string; lastName: string };
  blockadeReasons: string[];
  onBlockPressed: (reason: string, comment?: string) => void;
}

const BlockUserPopover = (props: Props) => {
  const { t } = useTranslation('users');
  const [reason, setReason] = useState<string>();
  const [comment, setComment] = useState('');

  const handleConfirmPress = () => {
    if (reason) props.onBlockPressed(reason, comment);
  };

  return (
    <FormContainer>
      <FormHeader>
        {t('block-label')} <b>{`${props.userData.firstName} ${props.userData.lastName}`}</b>
      </FormHeader>
      <DropdownWithLabel
        label={t('filters.reason')}
        value={reason}
        onChange={setReason}
        options={props.blockadeReasons}
        placeholder={t('select-reason')}
        minWidth={300}
      />
      <InputWithLabel label={t('comment')} onChange={setComment} value={comment} multiline={true} minWidth={300} />
      <ConfirmButton label={t('block-btn')} onClick={handleConfirmPress} disabled={!reason} />
    </FormContainer>
  );
};

export default BlockUserPopover;
