import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuctionDto, BidDto } from '../../../../services/Auction/auctionService.dto';
import { theme } from '../../../../assets/styles/theme';
import { TextMain } from '../../../../components/typography/Texts';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';
import { UserPublicDto } from '../../../../services/User/userService.dto';
import { userService } from '../../../../services/User/userService';
import { useError } from '../../../../providers/useError';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  position: relative;
  max-width: 420px;
`;

const BidRow = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  align-items: center;
`;

const Label = styled(TextMain)``;

interface Props {
  auction: AuctionDto;
  onConfirmPress: (auctionId: number, bidId: number) => void;
}

const ChooseNewWinnerPopover = ({ auction, onConfirmPress }: Props) => {
  const [bidders, setBidders] = useState<UserPublicDto[]>([]);
  const [selectedBidId, setSelectedBidId] = useState<number | undefined>(auction.winningBidId);

  const { t } = useTranslation('items');
  const { handleError } = useError();

  useEffect(() => {
    const uniqueUserIds = Array.from(new Set(auction.bids.map(bid => bid.userId)));
    userService
      .fetchPublicUsers(uniqueUserIds)
      .then(response => setBidders(response.data))
      .catch(e => handleError(e));
  }, []);

  const handleConfirmPress = () => {
    selectedBidId && onConfirmPress(auction.id, selectedBidId);
  };

  const extractBidder = (bid: BidDto): string => {
    const bidder = bidders.find(bidder => bidder.id === bid.userId);
    return bidder
      ? `${bidder.firstName} ${bidder.lastName} (${t('auctions.user-id')}: ${bidder.id}), ${bid.amount} ${
          auction.currency
        }`
      : '';
  };

  const renderBidRow = (bid: BidDto) => (
    <BidRow key={bid.id}>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedBidId === bid.id}
            onChange={() => setSelectedBidId(bid.id)}
            style={{ color: theme.color.red }}
          />
        }
        label={<Label>{extractBidder(bid)}</Label>}
      />
    </BidRow>
  );

  return (
    <FormContainer>
      {bidders &&
        auction.bids
          .sort((bidA, bidB) => new Date(bidB.created).getTime() - new Date(bidA.created).getTime())
          .map(bid => {
            return renderBidRow(bid);
          })}
      <ConfirmButton label={t('auctions.new-winner.bidders.button-text')} onClick={handleConfirmPress} />
    </FormContainer>
  );
};

export default ChooseNewWinnerPopover;
