import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import { Popover, PopoverOrigin } from '@mui/material';
import { v4 as uuid } from 'uuid';
import closeIcon from '../assets/svg/close.svg';
import ErrorPopup from '../components/dialogs/ErrorPopup';
import SuccessPopup from '../components/dialogs/SuccessPopup';
import ConfirmPopup from '../components/dialogs/ConfirmPopup';

const PopoverContainer = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 700px;
  max-height: 90vh;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
`;

interface PopoverData {
  id: string;
  anchor?: SVGSVGElement | HTMLElement;
  content: ReactNode;
}

interface PopupContextType {
  showPopover: (content: ReactNode, anchor?: SVGSVGElement | HTMLElement) => void;
  hidePopover: () => void;
  showConfirmPopover: (msg: string, onYesClick: () => void) => void;
  showSuccessPopover: (msg: string, onClose?: () => void) => void;
  showErrorPopover: (msg: string, onClose?: () => void) => void;
}

const PopupContext = createContext<PopupContextType>(null!);

const PopupProvider: FC<{ children: ReactNode }> = props => {
  const [popovers, setPopovers] = useState<PopoverData[]>([]);

  const showPopover = (content: ReactNode, anchor?: SVGSVGElement | HTMLElement) => {
    const popoverData: PopoverData = { id: uuid(), anchor, content };
    setPopovers(prevState => [...prevState, popoverData]);
  };

  const showConfirmPopover = (msg: string, onYesClick: () => void) => {
    showPopover(
      <ConfirmPopup
        msg={msg}
        onYesClick={() => {
          hidePopover();
          onYesClick();
        }}
        onNoClick={() => hidePopover()}
      />
    );
  };

  const showSuccessPopover = (msg: string, onClose?: () => void) => {
    showPopover(
      <SuccessPopup
        msg={msg}
        onClose={() => {
          hidePopover();
          onClose && onClose();
        }}
      />
    );
  };

  const showErrorPopover = (msg: string, onClose?: () => void) => {
    showPopover(
      <ErrorPopup
        msg={msg}
        onClose={() => {
          hidePopover();
          onClose && onClose();
        }}
      />
    );
  };

  const hidePopover = (id?: string) => {
    if (!id) setPopovers(prevState => prevState.slice(0, prevState.length - 1));
    else setPopovers(prevState => prevState.filter(popover => popover.id !== id));
  };

  const popoverOrigin: PopoverOrigin = { vertical: 'center', horizontal: 'left' };

  return (
    <PopupContext.Provider
      value={{
        showPopover,
        hidePopover,
        showConfirmPopover,
        showSuccessPopover,
        showErrorPopover,
      }}>
      {props.children}
      {popovers.map(popoverData => (
        <Popover
          key={popoverData.id}
          anchorEl={popoverData.anchor}
          anchorReference={popoverData.anchor ? undefined : 'none'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backdropFilter: 'blur(2px)',
          }}
          PaperProps={{ style: { overflow: 'visible' } }}
          open={true}
          onClose={() => hidePopover(popoverData.id)}
          elevation={5}
          anchorOrigin={popoverData.anchor && popoverOrigin}
          transformOrigin={popoverData.anchor && popoverOrigin}>
          <PopoverContainer>
            <CloseButton
              src={closeIcon}
              alt={'close'}
              onClick={event => {
                event.preventDefault();
                hidePopover(popoverData.id);
              }}
            />
            {popoverData.content}
          </PopoverContainer>
        </Popover>
      ))}
    </PopupContext.Provider>
  );
};

const usePopup = () => useContext(PopupContext);

export { PopupProvider, usePopup };
