import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import { CreateProviderLimitDto, CURRENCIES, DEFAULT_CURRENCY } from '../../../../services/Payment/paymentService.dto';
import { useProviders } from '../../providers/hooks/useProviders';
import { SectionHeader } from '../../../../components/typography/Headers';
import { FormTable, TableWrapper } from '../../../../components/forms/Containers';
import FormRow from '../../../../components/forms/FormRow';
import PriceInput from '../../../../components/inputs/PriceInput';
import RectangularButton from '../../../../components/buttons/RectangularButton';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const SaveButton = styled(RectangularButton)`
  width: 100%;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
`;

interface Props {
  onConfirmPress: (request: CreateProviderLimitDto) => void;
}

const AddProviderLimitForm = ({ onConfirmPress }: Props) => {
  const { t } = useTranslation('payments');
  const { providers } = useProviders();

  const [providerId, setProviderId] = useState<number>();
  const [amount, setAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const [notes, setNotes] = useState('');

  const isValid = !!providerId && !!amount && !!currency;

  const providersMap = new Map<number, string>(
    providers?.map(provider => [provider.id, `[#${provider.id}] ${provider.comment}`])
  );

  const handleOnSave = () => {
    if (!providerId) return;
    const request: CreateProviderLimitDto = {
      providerId,
      limitAmount: amount,
      limitCurrency: currency,
      adminNote: notes,
    };
    onConfirmPress(request);
  };

  return (
    <FormContainer>
      <SectionHeader>{t('provider-limits.popovers.create-header')}</SectionHeader>
      <TableWrapper>
        <FormTable>
          <tbody>
            <FormRow label={t('provider-limits.popovers.provider')}>
              <DropdownWithLabel
                label={''}
                value={providerId?.toString()}
                onChange={value => (value ? setProviderId(Number.parseInt(value)) : setProviderId(undefined))}
                optionsMap={providersMap}
                hideEmptyOption={true}
                minWidth={300}
              />
            </FormRow>
            <FormRow label={t('provider-limits.popovers.amount')}>
              <PriceInput
                onPriceChange={setAmount}
                onCurrencyChange={setCurrency}
                price={amount || 0}
                currency={currency}
                availableCurrencies={CURRENCIES}
              />
            </FormRow>
            <FormRow label={t('provider-limits.popovers.note')}>
              <InputWithLabel label={''} onChange={setNotes} value={notes} multiline={true} rows={1} />
            </FormRow>
          </tbody>
        </FormTable>
      </TableWrapper>
      <SaveButton label={t('common:save')} onClick={handleOnSave} disabled={!isValid} />
    </FormContainer>
  );
};

export default AddProviderLimitForm;
