import { ItemFormData } from '../hooks/ItemContext';
import { ItemInfoDto } from '../../../services/Item/itemService.dto';
import { PHOTOS_COUNT_LIMIT } from '../../../components/forms/PhotosInput';
import { PaymentConfigDto } from '../../../services/Payment/paymentService.dto';

const MIN_ITEM_QUANTITY = 2;
const MAX_ITEM_QUANTITY = 999999;

export const validateItemForm = (
  formData: ItemFormData,
  item?: ItemInfoDto,
  paymentConfig?: PaymentConfigDto
): string[] => {
  const errors: string[] = [];
  if (!formData.type) errors.push('Item type required');
  if (!formData.sellingMethod) errors.push('Item Selling method required');
  if (!formData.campaign) errors.push('Supporting campaign required');
  if (!formData.user) errors.push('Item owner required');
  if (!formData.title) errors.push('Item title required');
  if (!formData.description && formData.type === 'SERVICE') errors.push('Item description required for SERVICE type');
  if (!formData.category) errors.push('Item category required');
  if (!formData.condition && formData.type !== 'SERVICE') errors.push('Item condition required');
  if (!formData.canLocalPickupDelivery && !formData.canNationwideShipping)
    errors.push('At least one delivery method required');
  if (!formData.itemLocation) errors.push('Item location required');
  if (!formData.itemLocation.zipCode) errors.push('Zip code required');
  if (!formData.itemLocation.city) errors.push('City required');
  if (!formData.itemLocation.state) errors.push('State required');
  if (!formData.itemLocation.latitude) errors.push('Latitude required');
  if (!formData.itemLocation.longitude) errors.push('Longitude required');
  if (!formData.weightUpTo && formData.canNationwideShipping)
    errors.push('Item weight required for nationwide shipping');
  if (formData.sellingMethod === 'MULTIPLE') {
    if (!formData.price || formData.price <= 0) errors.push('Item price required for MULTIPLE selling method');
    if (!formData.quantity || formData.quantity < MIN_ITEM_QUANTITY)
      errors.push('Item quantity required for MULTIPLE selling method');
    if (formData.quantity && formData.quantity > MAX_ITEM_QUANTITY)
      errors.push(`Item quantity cannot be higher than ${MAX_ITEM_QUANTITY}`);
  }
  if (formData.sellingMethod === 'AUCTION') {
    if (!formData.auctionEndDate) errors.push('Auction end date required for AUCTION selling method');
    if (formData.auctionEndDate && formData.auctionEndDate.getTime() < new Date().getTime())
      errors.push('Auction end date cannot be in the past');
  }
  if ((!formData.price || formData.price <= 0) && !formData.negotiable && formData.sellingMethod !== 'AUCTION')
    errors.push('Item price required if not negotiable');
  if (formData.price && paymentConfig && formData.price > paymentConfig.maxItemPrice)
    errors.push(`Item price cannot be higher than: ${paymentConfig.maxItemPrice}`);
  if (formData.photos.length === 0) errors.push('Item image required');
  if (formData.photos.length > PHOTOS_COUNT_LIMIT) errors.push('Max number of images exceeded');

  if (item) {
    if (formData.user?.id !== item.ownerId) errors.push('Item owner cannot be changed');
    if (formData.type !== item.itemType) errors.push('Item type cannot be changed');
    if (formData.sellingMethod !== item.sellingMethod) errors.push('Item selling method cannot be changed');
    if (item.unitsSold > 0) {
      if (formData.price !== item.price) errors.push('Item price cannot be changed if at least one unit sold');
      if (formData.campaign?.id !== item.supportedCampaignId)
        errors.push('Supporting campaign cannot be changed if at least one unit sold');
      if (formData.quantity && formData.quantity < item.unitsSold)
        errors.push('Quantity cannot be less than number of sold units');
    }
  }

  return errors;
};
