import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import { CURRENCIES, ProviderLimitDto, UpdateProviderLimitDto } from '../../../../services/Payment/paymentService.dto';
import { SectionHeader } from '../../../../components/typography/Headers';
import { FormTable, TableWrapper } from '../../../../components/forms/Containers';
import FormRow from '../../../../components/forms/FormRow';
import PriceInput from '../../../../components/inputs/PriceInput';
import RectangularButton from '../../../../components/buttons/RectangularButton';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const SaveButton = styled(RectangularButton)`
  width: 100%;
  font-weight: bold;
  height: 40px;
  position: absolute;
  bottom: 0;
`;

interface Props {
  providerLimit: ProviderLimitDto;
  onConfirmPress: (request: UpdateProviderLimitDto) => void;
}

const UpdateProviderLimitForm = ({ providerLimit, onConfirmPress }: Props) => {
  const { t } = useTranslation('payments');

  const [amount, setAmount] = useState<number>(providerLimit.limitAmount);
  const [notes, setNotes] = useState(providerLimit.adminNote || '');

  const isValid = !!amount;
  const isChanged = amount !== providerLimit.limitAmount || notes !== providerLimit.adminNote;

  const handleOnSave = () => {
    const request: UpdateProviderLimitDto = {
      limitAmount: amount,
      adminNote: notes,
    };
    onConfirmPress(request);
  };

  return (
    <FormContainer>
      <SectionHeader>{t('provider-limits.popovers.update-header', { id: providerLimit.id })}</SectionHeader>
      <TableWrapper>
        <FormTable>
          <tbody>
            <FormRow label={t('provider-limits.popovers.provider')}>
              {`[#${providerLimit.providerId}] ${providerLimit.providerDescription}`}
            </FormRow>
            <FormRow label={t('provider-limits.popovers.amount')}>
              <PriceInput
                onPriceChange={setAmount}
                price={amount || 0}
                currency={providerLimit.limitCurrency}
                availableCurrencies={CURRENCIES}
              />
            </FormRow>
            <FormRow label={t('provider-limits.popovers.note')}>
              <InputWithLabel label={''} onChange={setNotes} value={notes} multiline={true} rows={1} />
            </FormRow>
          </tbody>
        </FormTable>
      </TableWrapper>
      <SaveButton label={t('common:save')} onClick={handleOnSave} disabled={!isValid || !isChanged} />
    </FormContainer>
  );
};

export default UpdateProviderLimitForm;
