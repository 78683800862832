import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentService } from '../../../../services/Payment/paymentService';
import { usePopup } from '../../../../providers/PopupProvider';
import {
  CreateProviderLimitDto,
  ProviderLimitDto,
  UpdateProviderLimitDto,
} from '../../../../services/Payment/paymentService.dto';
import { useError } from '../../../../providers/useError';

export const useProviderLimits = () => {
  const { t } = useTranslation('payments');
  const { showConfirmPopover } = usePopup();
  const { errorMessage } = useError();

  const [providerLimits, setProviderLimits] = useState<ProviderLimitDto[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchProviderLimits();
  }, []);

  const fetchProviderLimits = () => {
    setIsLoading(true);
    paymentService
      .fetchProviderLimits()
      .then(response => setProviderLimits(response.data))
      .catch(() => errorMessage())
      .finally(() => setIsLoading(false));
  };

  const addProviderLimit = (request: CreateProviderLimitDto) => {
    setIsLoading(true);
    paymentService
      .addProviderLimit(request)
      .then(() => fetchProviderLimits())
      .catch(error => errorMessage(error.response?.data.errorCode))
      .finally(() => setIsLoading(false));
  };

  const updateProviderLimit = (id: number, request: UpdateProviderLimitDto) => {
    setIsLoading(true);
    paymentService
      .updateProviderLimit(id, request)
      .then(() => fetchProviderLimits())
      .catch(error => errorMessage(error.response?.data.errorCode))
      .finally(() => setIsLoading(false));
  };

  const deleteProviderLimit = (id: number) => {
    const onConfirm = () => {
      setIsLoading(true);
      paymentService
        .deleteProviderLimit(id)
        .then(() => fetchProviderLimits())
        .catch(error => errorMessage(error.response?.data.errorCode))
        .finally(() => setIsLoading(false));
    };
    showConfirmPopover(t('provider-limits.popovers.delete-confirmation', { id }), onConfirm);
  };

  return { providerLimits, addProviderLimit, updateProviderLimit, deleteProviderLimit, isLoading };
};
