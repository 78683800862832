import { TableFilterStateDto } from '../../../components/table/DataTable';
import { EditItemDto, ItemFilterDto, NewItemDto } from '../../../services/Item/itemService.dto';
import { parseMultiChoice } from '../../../components/table/filterConverterUtils';
import { ItemFormData } from '../hooks/ItemContext';

export const convertFiltersToItemFilterDto = (filter: TableFilterStateDto<Map<string, any>>): ItemFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    id: filter.filtersMap?.get('id'),
    title: filter.filtersMap?.get('title'),
    ownerId: filter.filtersMap?.get('ownerId'),
    campaignId: filter.filtersMap?.get('campaignId'),
    status: parseMultiChoice(filter, 'status'),
    type: parseMultiChoice(filter, 'type'),
    sellingMethod: parseMultiChoice(filter, 'sellingMethod'),
  };
};

export const convertFormDataToNewItemDto = (formData: ItemFormData): NewItemDto => {
  const imgUrls = formData.photos ? formData.photos.filter(photo => photo.safe).map(photo => photo.url || '') : [];
  return {
    canAcceptLoHiOffers: formData.sellingMethod === 'STANDARD' ? formData.negotiable : false,
    canLocalPickUpDelivery: formData.type === 'REGULAR' ? formData.canLocalPickupDelivery : true,
    canNationwideShipping: formData.type === 'REGULAR' ? formData.canNationwideShipping : false,
    category: formData.category?.code || '',
    condition: formData.type === 'REGULAR' ? formData.condition?.code || '' : undefined,
    description: formData.description,
    imageUrlList: imgUrls,
    videoUrl: !!formData.videoUrl ? formData.videoUrl : undefined,
    itemLocation: formData.itemLocation,
    price: formData.price ? formData.price : undefined,
    supportedCampaignId: formData.campaign!.id,
    title: formData.title || '',
    weightUpTo: formData.type === 'REGULAR' && formData.canNationwideShipping ? formData.weightUpTo : undefined,
    itemType: formData.type!,
    sellingMethod: formData.sellingMethod!,
    unitsQuantity: formData.sellingMethod === 'MULTIPLE' ? formData.quantity : 1,
    auction: {
      minimumPrice: formData.sellingMethod === 'AUCTION' && formData.price ? formData.price : undefined,
      endDateTime:
        formData.sellingMethod === 'AUCTION' && formData.auctionEndDate
          ? formData.auctionEndDate.toISOString()
          : undefined,
    },
  };
};

export const convertFormDataToEditItemDto = (formData: ItemFormData, itemId: number): EditItemDto => {
  return { ...convertFormDataToNewItemDto(formData), id: itemId };
};
