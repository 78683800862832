import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../../../components/typography/PopoverTypography';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  width: 500px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

interface Props {
  onClosePressed: () => void;
  onContinuePressed: () => void;
}

const GeneratePopover = (props: Props) => {
  const { t } = useTranslation('payments');
  return (
    <FormContainer>
      <FormHeader>
        <b>{t('withdraw-money.confirmation-header')}</b>
      </FormHeader>
      <FormHeader>{t('withdraw-money.confirmation-description')}</FormHeader>
      <ButtonsWrapper>
        <ConfirmButton label={t('withdraw-money.continue-btn')} onClick={props.onContinuePressed} />
        <ConfirmButton label={t('withdraw-money.cancel-btn')} onClick={props.onClosePressed} />
      </ButtonsWrapper>
    </FormContainer>
  );
};

export default GeneratePopover;
