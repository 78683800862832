import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../assets/styles/theme';
import { CampaignAdminDto } from '../../../../services/Campaign/campaignService.dto';
import CampaignsListItem from './CampaignsListItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35px;
  max-height: 250px;
  background-color: ${theme.color.white};
  border-radius: 10px;
  border: 2px solid ${theme.color.lightGray};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0;
  z-index: 1;
`;

const NoContentInfo = styled(Container)`
  padding: 10px 0;
`;

interface Props {
  campaigns?: CampaignAdminDto[];
  onCampaignSelected: (campaign: CampaignAdminDto) => void;
}

const CampaignsList = ({ campaigns, onCampaignSelected }: Props) => {
  const { t } = useTranslation('campaigns');
  if (!campaigns) return <></>;
  if (campaigns.length === 0) return <NoContentInfo>{t('no-campaigns-found')}</NoContentInfo>;
  return (
    <Container>
      {campaigns.map(campaign => (
        <CampaignsListItem key={campaign.id} campaign={campaign} onClick={() => onCampaignSelected(campaign)} />
      ))}
    </Container>
  );
};

export default CampaignsList;
