import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { theme } from '../../assets/styles/theme';

const StyledButton = styled.button<{ disabled?: boolean }>`
  background-color: ${props => (props.disabled ? theme.color.disabled : theme.color.white)};
  color: ${props => (props.disabled ? theme.color.white : theme.color.red)};
  width: 40px;
  aspect-ratio: 1;
  border-radius: 6px;
  border: 2px solid ${props => (props.disabled ? theme.color.disabled : theme.color.red)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};

  &:hover {
    background-color: ${props => (props.disabled ? theme.color.disabled : theme.color.red)};
    color: ${theme.color.white};
  }
`;

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const DeleteButton = ({ onClick, disabled }: Props) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <DeleteIcon style={{ fontSize: 25 }} />
    </StyledButton>
  );
};

export default DeleteButton;
