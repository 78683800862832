import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TableFilterStateDto } from '../../../../components/table/DataTable';
import { parseMultiChoice, prepareInitialFilter } from '../../../../components/table/filterConverterUtils';
import { paymentService } from '../../../../services/Payment/paymentService';
import { TransactionsFilterDto } from '../../../../services/Payment/paymentService.dto';
import { dateUtils } from '../../../../utils/dateUtils';

type UrlParams = {
  transactionId: string;
};

export const useTransactionFilters = () => {
  const navigate = useNavigate();
  const { transactionId } = useParams<UrlParams>();

  const onTableFiltered = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const filter = convertFiltersToTransactionsFilterDto(tableState);
    navigate(`/payments/transactions/${filter.id || ''}`);
    return paymentService.fetchTransactions(filter).then(response => response.data);
  }, []);

  const filterInitState = useMemo(() => prepareInitialFilter(transactionId), [transactionId]);

  return { onTableFiltered, filterInitState };
};

const convertFiltersToTransactionsFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>
): TransactionsFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'transactionDate,desc',
    id: filter.filtersMap?.get('id'),
    orderId: filter.filtersMap?.get('orderId'),
    status: parseMultiChoice(filter, 'status'),
    paymentAgent: parseMultiChoice(filter, 'paymentAgent'),
    currency: parseMultiChoice(filter, 'currency'),
    payerId: filter.filtersMap?.get('payerId'),
    totalAmountFrom: !!filter.filtersMap?.get('amount') ? filter.filtersMap?.get('amount')[0] : undefined,
    totalAmountTo: !!filter.filtersMap?.get('amount') ? filter.filtersMap?.get('amount')[1] : undefined,
    creationDateDay: !!filter.filtersMap?.get('date')
      ? dateUtils.formatYearMonthDay(filter.filtersMap?.get('date'))
      : undefined,
    errors: !!filter.filtersMap?.get('errors') ? true : undefined,
    overLimit: !!filter.filtersMap?.get('overLimit') ? true : undefined,
  };
};
