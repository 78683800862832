import { LocationDto } from '../Report/reportService.dto';
import { LocationInfoDto } from '../Shipment/shipmentService.dto';

export const ITEM_STATUSES = ['PUBLISHED', 'SOLD', 'ARCHIVED', 'UNSOLD'] as const;
export const ITEM_TYPES = ['REGULAR', 'SERVICE'] as const;
export const SELLING_METHODS = ['STANDARD', 'AUCTION', 'MULTIPLE'] as const;

export type ItemStatus = (typeof ITEM_STATUSES)[number];
export type ItemType = (typeof ITEM_TYPES)[number];
export type SellingMethod = (typeof SELLING_METHODS)[number];

export interface ItemPublicDto {
  id: number;
  title: string;
  imageUrlList?: string[];
  price: number;
  currency: string;
  description: string;
  canLocalPickUpDelivery: boolean;
  canNationwideShipping: boolean;
  itemLocation: LocationDto;
  condition: string;
  ownerId: number;
}

export interface ItemInfoDto {
  id: number;
  title: string;
  category: string;
  description: string;
  supportedCampaignId: number;
  imageUrlList?: string[];
  price?: number;
  currency: string;
  canLocalPickUpDelivery: boolean;
  canNationwideShipping: boolean;
  canAcceptLoHiOffers: boolean;
  itemLocation: LocationInfoDto;
  weightUpTo?: number;
  unitOfMeasure?: string;
  condition: string;
  ownerId: number;
  created: string;
  hidden: boolean;
  itemStatus: ItemStatus;
  sellingMethod: SellingMethod;
  itemType: ItemType;
  unitsQuantity: number;
  unitsSold: number;
  defaultShipFromAddressId?: number;
  adminSortingBoost: number;
  videoUrl?: string;
}

export interface ItemFilterDto {
  page: number;
  size: number;
  sort?: string;
  id?: number;
  title?: string;
  ownerId?: number;
  campaignId?: number;
  status?: string;
  type?: string;
  sellingMethod?: string;
}

export interface ItemConditionDto {
  code: string;
  displayName: string;
  shortDisplayName: string;
}

export interface ItemCategoryDto {
  displayName: string;
  code: string;
  imageUrl: string;
  categoryOrder: number;
  itemType: ItemType;
}

export interface NewItemDto {
  canAcceptLoHiOffers: boolean;
  canLocalPickUpDelivery: boolean;
  canNationwideShipping: boolean;
  category: string;
  condition?: string;
  description?: string;
  imageUrlList: string[];
  itemLocation?: LocationInfoDto;
  price?: number;
  supportedCampaignId: number;
  title: string;
  weightUpTo?: number;
  itemType: ItemType;
  sellingMethod: SellingMethod;
  unitsQuantity?: number;
  auction?: {
    endDateTime?: string;
    minimumPrice?: number;
  };
  videoUrl?: string;
}

export interface EditItemDto extends NewItemDto {
  id: number;
  defaultShipFromAddressId?: number;
}
