import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';
import { useItemForm } from '../../hooks/ItemContext';

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0 5px;
`;

const VideoUrlInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setVideoUrl } = useItemForm();

  return (
    <FormRow label={t('add-item.video-url')}>
      <StyledInput
        label={''}
        onChange={setVideoUrl}
        value={formData.videoUrl || ''}
        placeholder={t('add-item.video-url-placeholder')}
      />
    </FormRow>
  );
};

export default VideoUrlInputRow;
