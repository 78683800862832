import React, { useContext, useEffect, useState } from 'react';
import { authService } from '../services/Auth/authService';
import { userService } from '../services/User/userService';
import { CampaignCategoryDto } from '../services/Campaign/campaignService.dto';
import { campaignService } from '../services/Campaign/campaignService';
import { AppVersionResponse } from '../services/FrontendMeta/frontendMetaService.dto';
import { frontendMetaService } from '../services/FrontendMeta/frontendMetaService';
import { ItemCategoryDto, ItemConditionDto } from '../services/Item/itemService.dto';
import { itemService } from '../services/Item/itemService';
import { DEFAULT_CURRENCY, PaymentConfigDto } from '../services/Payment/paymentService.dto';
import { paymentService } from '../services/Payment/paymentService';
import { useAuth } from './AuthProvider';
import { EmployeeWithPublicDataDto } from './employeeType';

interface GlobalDataContextType {
  supporters: EmployeeWithPublicDataDto[];
  blockadeReasons: string[];
  campaignCategories: CampaignCategoryDto[];
  appVersions: AppVersionResponse[];
  itemConditions: ItemConditionDto[];
  itemCategories: ItemCategoryDto[];
  paymentConfig?: PaymentConfigDto;
}

const GlobalDataContext = React.createContext<GlobalDataContextType | null>(null);

function GlobalDataProvider({ children }: any) {
  const [supporters, setSupporters] = useState<EmployeeWithPublicDataDto[]>([]);
  const [blockadeReasons, setBlockadeReasons] = useState<string[]>([]);
  const [campaignCategories, setCampaignCategories] = useState<CampaignCategoryDto[]>([]);
  const [appVersions, setAppVersions] = useState<AppVersionResponse[]>([]);
  const [itemConditions, setItemConditions] = useState<ItemConditionDto[]>([]);
  const [itemCategories, setItemCategories] = useState<ItemCategoryDto[]>([]);
  const [paymentConfig, setPaymentConfig] = useState<PaymentConfigDto>();

  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      fetchSupporters();
      fetchBlockadeReasons();
      fetchCampaignCategories();
      fetchAppVersions();
      fetchItemConditions();
      fetchItemCategories();
      fetchPaymentConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchSupporters = async () => {
    const { data: employees } = await authService.fetchEmployees();

    const userIds = employees.map(employee => employee.userId);
    const { data: users } = await userService.fetchPublicUsers(userIds);

    setSupporters(employees.map(employee => ({ ...employee, ...users.find(user => user.id === employee.userId)! })));
  };

  const fetchBlockadeReasons = () => {
    userService.fetchBlockadeReasons().then(response => setBlockadeReasons(response.data.map(item => item.name)));
  };

  const fetchCampaignCategories = () => {
    campaignService.fetchCategories().then(response => setCampaignCategories(response.data));
  };

  const fetchAppVersions = () => {
    frontendMetaService.getVersions().then(response => setAppVersions(response.data));
  };

  const fetchItemConditions = () => {
    itemService.fetchConditions().then(response => setItemConditions(response.data));
  };

  const fetchItemCategories = () => {
    itemService.fetchCategories().then(response => setItemCategories(response.data));
  };

  const fetchPaymentConfig = () => {
    paymentService.fetchClientConfiguration(DEFAULT_CURRENCY).then(response => setPaymentConfig(response.data));
  };

  return (
    <GlobalDataContext.Provider
      value={{
        supporters,
        blockadeReasons,
        campaignCategories,
        appVersions,
        itemConditions,
        itemCategories,
        paymentConfig,
      }}>
      {children}
    </GlobalDataContext.Provider>
  );
}

const useGlobalData = () => {
  const context = useContext(GlobalDataContext);
  if (context == null) {
    throw new Error('useGlobalData() called outside of a GlobalDataProvider?');
  }
  return context;
};

export { GlobalDataProvider, useGlobalData };
