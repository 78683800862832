import { useEffect, useState } from 'react';
import { useError } from '../../../../providers/useError';
import { TransactionBillingDataDto } from '../../../../services/Payment/paymentService.dto';
import { paymentService } from '../../../../services/Payment/paymentService';

export const useTransactionBillingData = (transactionId: number) => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionBillingData, setTransactionBillingData] = useState<TransactionBillingDataDto>();

  useEffect(() => {
    setIsLoading(true);
    paymentService
      .fetchTransactionBillingData(transactionId)
      .then(response => setTransactionBillingData(response.data))
      .catch(error => handleError(error))
      .finally(() => setIsLoading(false));
  }, [transactionId]);

  return { transactionBillingData, isLoading };
};
