import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AddCashIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { usePopup } from '../../../providers/PopupProvider';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import AddManualDonationForm from './AddManualDonationForm';
import CampaignForm from './form/CampaignForm';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  campaign: CampaignAdminDto;
  onUpdated: () => void;
}

const CampaignActions = ({ campaign, onUpdated }: Props) => {
  const { t } = useTranslation('campaigns');
  const { showPopover, hidePopover } = usePopup();

  const onAddDonation = () => {
    const handleDonationCompleted = () => {
      hidePopover();
      onUpdated();
    };
    showPopover(<AddManualDonationForm campaign={campaign} onDonationCompleted={handleDonationCompleted} />);
  };

  if (campaign.status !== 'ACTIVE') return <></>;
  return (
    <Container>
      <StyledButton title={t('campaigns.manual-donations.tooltip')} onClick={() => onAddDonation()}>
        <AddCashIcon style={iconStyle} />
      </StyledButton>
      <StyledButton
        title={t('campaigns.edit.tooltip')}
        onClick={() => showPopover(<CampaignForm campaignId={campaign.id} onCampaignSaved={onUpdated} />)}>
        <EditIcon style={iconStyle} />
      </StyledButton>
    </Container>
  );
};

export default CampaignActions;
