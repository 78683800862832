import React, { ReactNode } from 'react';
import styled from 'styled-components';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { theme } from '../../assets/styles/theme';
import { TextSmall } from '../typography/Texts';
import { usePopup } from '../../providers/PopupProvider';
import LoadingIndicator from '../loading/LoadingIndicator';

const Container = styled.div`
  width: 100%;
`;

const Label = styled(TextSmall)`
  color: ${theme.color.darkerGray};
`;

const Value = styled(TextSmall)`
  color: ${theme.color.black};
  font-size: 2rem;
  white-space: pre-wrap;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-left: 5px;
  padding: 2px;
  display: flex;
  align-items: center;
`;

interface Props {
  label: string;
  value?: string;
  popover?: ReactNode;
  loading?: boolean;
}

const CardParamInfo = ({ label, value, popover, loading }: Props) => {
  const { showPopover } = usePopup();
  return (
    <Container>
      <Label>{label}: </Label>
      <Row>
        {loading && <LoadingIndicator size={18} />}
        <Value>{value}</Value>
        {popover && !loading && (
          <ButtonContainer onClick={() => popover && showPopover(popover)}>
            <SearchSharpIcon style={{ fontSize: 20, cursor: 'pointer' }} />
          </ButtonContainer>
        )}
      </Row>
    </Container>
  );
};

export default CardParamInfo;
