import React, { useCallback, useEffect, useRef, useState } from 'react';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  grantEmployeeRole,
  credentialActionResult,
  revokeEmployeeRole,
  allowedDomains,
  AdminFilter,
  actionErrorMap,
  convertFilters,
} from '../hooks/userCredentilasUtils';
import { theme } from '../../../assets/styles/theme';
import InputWithLabel from '../../../components/inputs/InputWithLabel';
import RectangularButton from '../../../components/buttons/RectangularButton';
import DataTable, { Column, TableFilterStateDto } from '../../../components/table/DataTable';
import { CredentialDto } from '../../../services/Auth/authService.dto';
import { authService } from '../../../services/Auth/authService';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { useAuth } from '../../../providers/AuthProvider';
import { TextSmall } from '../../../components/typography/Texts';
import { useError } from '../../../providers/useError';
import { usePopup } from '../../../providers/PopupProvider';

const FilterSection = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
`;

const ApplyButton = styled(RectangularButton)`
  min-width: 80px;
  font-weight: normal;
  height: 30px;
  margin: 5px;
`;

const DomainInfo = styled(TextSmall)`
  color: ${theme.color.lightBlack};
  margin-bottom: 10px;
`;

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
};

interface Props {
  displayAdmins: boolean;
}

const AdminsTable = (props: Props) => {
  const { t } = useTranslation('users');
  const { handleError, errorMessage } = useError();
  const [filterData, setFilterData] = useState<AdminFilter>();
  const [filterState, setFilterState] = useState<AdminFilter>({ employee: false });
  const dataTableRef = useRef<DataTableRef>(null);
  const { currentUser } = useAuth();
  const { showConfirmPopover, hidePopover } = usePopup();

  useEffect(() => {
    if (props.displayAdmins !== filterState.employee) clearFilters(props.displayAdmins);
  }, [props.displayAdmins]);

  const clearFilters = (employee: boolean) => {
    setFilterState({ employee });
    setFilterData({ employee });
  };

  const requestErrorMap = new Map([
    [404, t('error.credential-not-found')],
    [409, t('error.role-frozen')],
    [422, t('error.empty-authorities')],
  ]);

  const columns: Column<CredentialDto>[] = [
    {
      id: 'username',
      title: t('columns.username'),
      isSortable: true,
      align: 'left',
      renderCell: data => data.username,
    },
    {
      id: 'role',
      title: t('columns.role'),
      align: 'left',
      renderCell: data => (data.isEmployee ? t('role-employee').toString() : t('role-user').toString()),
    },
    {
      id: 'actions',
      title: t('columns.actions'),
      align: 'left',
      renderCell: data => {
        if (data.isEmployee && currentUser?.id !== data.userId) {
          return (
            <div title={t('revoke-tooltip')}>
              <CancelPresentationIcon
                style={iconStyle}
                onClick={() =>
                  showConfirmPopover(t('revoke-employee', { username: data.username }), () => onAccept(data))
                }
              />
            </div>
          );
        } else if (!data.isEmployee) {
          return (
            <div title={t('grant-tooltip')}>
              <VpnKeyIcon
                style={iconStyle}
                onClick={() =>
                  showConfirmPopover(t('grant-employee', { username: data.username }), () => onAccept(data))
                }
              />
            </div>
          );
        }
      },
    },
  ];

  const onAccept = (credentials?: CredentialDto) => {
    if (credentials) {
      const result = credentials.isEmployee ? revokeEmployeeRole(credentials) : grantEmployeeRole(credentials);
      if (result.actionResult === credentialActionResult.UPDATED)
        authService
          .updateCredentials(result.credentials.id, result.credentials.authorities)
          .then(() => dataTableRef.current?.refresh())
          .catch(e => handleError(e, t('common:error.generic'), requestErrorMap));
      else errorMessage(actionErrorMap.get(result.actionResult) || t('error.generic'));
    }
    hidePopover();
  };

  const handleApply = () => {
    setFilterData(filterState);
  };

  const fetchCredentials = useCallback((state: TableFilterStateDto<any>) => {
    return authService.fetchCredentials(convertFilters(state)).then(response => response.data);
  }, []);

  return (
    <>
      <FilterSection>
        <InputWithLabel
          key={'username'}
          label={t('filters.email')}
          value={filterState.username || ''}
          minWidth={300}
          onChange={value => setFilterState({ ...filterState, username: value })}
        />
        <ApplyButton label={t('common:apply')} onClick={handleApply} />
      </FilterSection>
      <DomainInfo>
        {props.displayAdmins ? '\u00a0' : t('allowed-domains', { domains: allowedDomains.join(', ') })}
      </DomainInfo>
      <DataTable columns={columns} filterData={filterData} onTableStateChanged={fetchCredentials} ref={dataTableRef} />
    </>
  );
};

export default AdminsTable;
