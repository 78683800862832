import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentService } from '../../../../services/Payment/paymentService';
import { usePopup } from '../../../../providers/PopupProvider';
import { PaymentInfoDto } from '../../../../services/Payment/paymentService.dto';
import { useError } from '../../../../providers/useError';

export const usePayments = () => {
  const { t } = useTranslation('payments');
  const { showConfirmPopover } = usePopup();
  const { errorMessage } = useError();

  const [payments, setPayments] = useState<PaymentInfoDto[]>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    paymentService.fetchPayments().then(response => setPayments(response.data));
  }, []);

  const setPaymentEnabled = (id: number, enabled: boolean) => {
    const msg = enabled
      ? t('payment-providers.confirm-enable-dialog', { id })
      : t('payment-providers.confirm-disable-dialog', { id });
    showConfirmPopover(msg, () => {
      setIsSaving(true);
      paymentService
        .updatePayment(id, enabled)
        .then(updatedPayment => {
          setPayments(prevState =>
            prevState?.map(payment => (payment.id === updatedPayment.data.id ? updatedPayment.data : payment))
          );
        })
        .catch(e => errorMessage(e.message))
        .finally(() => setIsSaving(false));
    });
  };

  return { payments, setPaymentEnabled, isSaving };
};
