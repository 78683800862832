import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import RectangularButton from '../../../../components/buttons/RectangularButton';
import { useMessages } from '../../../../providers/messages/MessagesProvider';
import { useAuth } from '../../../../providers/AuthProvider';
import { ConversationStatus } from '../../../../services/Message/messageService.dto';
import { useGlobalData } from '../../../../providers/GlobalDataProvider';
import { theme } from '../../../../assets/styles/theme';
import { usePopup } from '../../../../providers/PopupProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(RectangularButton)`
  margin: 10px 20px 0 20px;
  padding: 10px;
  width: 200px;
`;

const SupportersListContainer = styled.div`
  width: 200px;
  max-height: 500px;
  padding: 10px;
`;

const SupporterContainer = styled.div`
  cursor: pointer;
  padding: 5px 0;

  &:hover {
    background-color: ${theme.color.lightGray};
  }
`;

const ActionsList = () => {
  const { conversation, transferConversation, resolveConversation, reopenConversation } = useMessages();
  const { currentUser } = useAuth();
  const { supporters } = useGlobalData();
  const { t } = useTranslation('messages');
  const { showPopover, hidePopover } = usePopup();

  const handleTransferConversation = (supporterId: number) => {
    hidePopover();
    transferConversation(supporterId);
  };

  const handleOvertakeConversation = () => {
    if (currentUser && currentUser.id) transferConversation(currentUser.id);
  };

  const renderSupporters = () => {
    return (
      <SupportersListContainer>
        {supporters
          .filter(supporter => supporter.id !== conversation?.endUser?.id)
          .map(supporter => {
            return (
              <SupporterContainer key={supporter.id} onClick={() => handleTransferConversation(supporter.id)}>
                {supporter.firstName} {supporter.lastName}
              </SupporterContainer>
            );
          })}
      </SupportersListContainer>
    );
  };

  const renderActions = () => {
    const isConversationWithMe = conversation?.endUserId === currentUser?.id;

    const status = conversation?.status;
    if (status === ConversationStatus.WAITING || status === ConversationStatus.CLOSED) {
      return (
        <Button disabled={isConversationWithMe} label={t('action-overtake')} onClick={handleOvertakeConversation} />
      );
    } else if (status === ConversationStatus.ONGOING) {
      if (conversation?.supporter?.id === currentUser?.id) {
        if (conversation?.resolved) {
          return <Button label={t('action-reopen')} onClick={() => reopenConversation()} />;
        } else {
          return (
            <>
              <Button label={t('action-resolve')} onClick={() => resolveConversation()} />
              <div onClick={e => showPopover(renderSupporters(), e.currentTarget)}>
                <Button label={t('action-transfer')} />
              </div>
            </>
          );
        }
      } else {
        return (
          <Button
            disabled={isConversationWithMe}
            label={t('action-overtake')}
            onClick={() => handleOvertakeConversation()}
          />
        );
      }
    }
  };

  if (!conversation || !conversation.status) return <></>;
  return <Container>{renderActions()}</Container>;
};

export default ActionsList;
