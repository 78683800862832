import { AxiosPromise } from 'axios';
import { apiUrl, apiService } from '../apiService';
import PageableType from '../utils/pageableType';
import { PinDto } from '../../features/users/components/VerificationPopover';
import {
  UserDto,
  UserDetailsDto,
  UserPublicDto,
  UserFilterDto,
  FundraiserDetailsDto,
  FundraiserFilterDto,
  FundraiserPageUpdateDto,
} from './userService.dto';

class UserService {
  static BASE_URL = `${apiUrl}/api/user`;

  fetchUserCurrent(): AxiosPromise<UserDto> {
    const url = `${UserService.BASE_URL}/users/current`;
    return apiService.get(url);
  }

  fetchPublicUsers(userIds: number[]): AxiosPromise<UserPublicDto[]> {
    const url = `${UserService.BASE_URL}/public/users/bulk`;
    return apiService.get(url, { ids: userIds.join(',') });
  }

  fetchPublicUser(id: number): AxiosPromise<UserPublicDto> {
    const url = `${UserService.BASE_URL}/public/users/${id}`;
    return apiService.get(url);
  }

  fetchBlockadeReasons(): AxiosPromise<{ name: string }[]> {
    const url = `${UserService.BASE_URL}/admin/blockade-reasons`;
    return apiService.get(url);
  }

  fetchUsers(filter: UserFilterDto, blocked?: boolean): AxiosPromise<PageableType<UserDetailsDto>> {
    const url = `${UserService.BASE_URL}/admin/users`;
    return apiService.get(url, { ...filter, blocked });
  }

  fetchSingleUser(id: number): Promise<UserDetailsDto> {
    const url = `${UserService.BASE_URL}/admin/users`;
    return apiService.get(url, { page: 0, size: 1, id: id }).then(response => {
      return response?.data?.content[0];
    });
  }

  fetchFundraisers(filter: FundraiserFilterDto): AxiosPromise<PageableType<FundraiserDetailsDto>> {
    const url = `${UserService.BASE_URL}/admin/fundraisers`;
    return apiService.get(url, { ...filter });
  }

  fetchSingleFundraiser(id: number): Promise<FundraiserDetailsDto> {
    const url = `${UserService.BASE_URL}/admin/fundraisers`;
    return apiService.get(url, { page: 0, size: 1, id: id }).then(response => {
      return response?.data?.content[0];
    });
  }

  verifyFundraiser(userId: number) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/fundraiser-verification`;
    return apiService.post(url);
  }

  blockUser(userId: number, reason: string, comment?: string) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/blockade`;
    return apiService.post(url, {}, {}, { comment, reason });
  }

  unblockUser(userId: number) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/blockade`;
    return apiService.delete(url);
  }

  closeAccount(userId: number) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}`;
    return apiService.delete(url);
  }

  fetchPhoneActivationPin(userId: number): AxiosPromise<PinDto> {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/phone-activation-pin`;
    return apiService.get(url);
  }

  confirmSignup(userId: number) {
    const url = `${UserService.BASE_URL}/admin/signup-confirmation`;
    return apiService.post(url, {}, {}, { userId });
  }

  updateFundraiserPage(userId: number, fundraiserPageUpdateDto: FundraiserPageUpdateDto) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/fundraiser-page`;
    return apiService.put(url, {}, {}, fundraiserPageUpdateDto);
  }

  deleteFundraiserPage(userId: number) {
    const url = `${UserService.BASE_URL}/admin/users/${userId}/fundraiser-page`;
    return apiService.delete(url);
  }
}

const userService = new UserService();

export { userService, UserService };
