import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import DataTable, { Column } from '../../../../components/table/DataTable';
import { ProviderLimitDto } from '../../../../services/Payment/paymentService.dto';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { useProviderLimits } from '../hooks/useProviderLimits';
import { dateUtils } from '../../../../utils/dateUtils';
import UpdatedByCell from '../../../users/components/UpdatedByCell';
import { theme } from '../../../../assets/styles/theme';
import FloatingButton from '../../../../components/buttons/FloatingButton';
import { useProviderLimitsPopovers } from '../hooks/useProviderLimitsPopovers';
import ProviderLimitsActionsCell from './ProviderLimitsActionsCell';

const ProviderLimitsTable = () => {
  const { t } = useTranslation('payments');
  const { providerLimits, addProviderLimit, updateProviderLimit, deleteProviderLimit, isLoading } = useProviderLimits();
  const { showCreateProviderLimitPopover, showUpdateProviderLimitPopover } = useProviderLimitsPopovers();

  const columns: Column<ProviderLimitDto>[] = [
    {
      id: 'id',
      title: t('provider-limits.columns.id'),
      align: 'center',
      renderCell: data => data.id,
    },
    {
      id: 'providerId',
      title: t('provider-limits.columns.provider-id'),
      align: 'center',
      maxWidth: 50,
      renderCell: data => data.providerId,
    },
    {
      id: 'providerDescription',
      title: t('provider-limits.columns.provider-description'),
      renderCell: data => data.providerDescription,
    },
    {
      id: 'limitAmount',
      title: t('provider-limits.columns.amount'),
      renderCell: data => data.limitAmount,
    },
    {
      id: 'limitCurrency',
      title: t('provider-limits.columns.currency'),
      maxWidth: 50,
      renderCell: data => data.limitCurrency,
    },
    {
      id: 'adminNote',
      title: t('provider-limits.columns.note'),
      maxWidth: 150,
      renderCell: data => data.adminNote,
    },
    {
      id: 'updateDate',
      title: t('provider-limits.columns.update-date'),
      align: 'center',
      maxWidth: 100,
      renderCell: data => dateUtils.formatDateDate(new Date(data.updateDate)),
    },
    {
      id: 'updatedBy',
      title: t('provider-limits.columns.updated-by'),
      align: 'center',
      maxWidth: 100,
      renderCell: data => <UpdatedByCell adminId={data.updatedBy} />,
    },
    {
      id: 'actions',
      title: t('provider-limits.columns.actions'),
      align: 'center',
      maxWidth: 50,
      renderCell: data => (
        <ProviderLimitsActionsCell
          onUpdateClick={() => showUpdateProviderLimitPopover(data, updateProviderLimit)}
          onDeleteClick={() => deleteProviderLimit(data.id)}
        />
      ),
    },
  ];

  const onTableStateChange = useCallback(() => {
    return Promise.resolve({ content: providerLimits, totalElements: providerLimits?.length });
  }, [providerLimits]);

  if (isLoading) return <LoadingIndicator />;
  return (
    <>
      <DataTable columns={columns} onTableStateChanged={onTableStateChange} disablePagination={true} />
      <FloatingButton onClick={() => showCreateProviderLimitPopover(addProviderLimit)}>
        <AddIcon style={{ color: theme.color.white, fontSize: 22 }} />
      </FloatingButton>
    </>
  );
};

export default ProviderLimitsTable;
