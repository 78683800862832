import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/containers/PageContainer';
import { Column, TableFilterStateDto } from '../../components/table/DataTable';
import { theme } from '../../assets/styles/theme';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import { UserDetailsDto } from '../../services/User/userService.dto';
import { userService } from '../../services/User/userService';
import { TableFilter } from '../../components/table/FiltersPanel';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import { dateUtils } from '../../utils/dateUtils';
import { useGlobalData } from '../../providers/GlobalDataProvider';
import { DataTableRef } from '../../components/table/dataTableRef';
import { useError } from '../../providers/useError';
import { usePopup } from '../../providers/PopupProvider';
import UserActions from './components/UserActions';
import UnblockUserPopover from './components/UnblockUserPopover';
import { convertFiltersToUserFilterDto } from './utils/filterConverter';
import { useUnblockUser } from './hooks/useUnblockUser';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const UsersBlocked = () => {
  const { t } = useTranslation('users');
  const { blockadeReasons } = useGlobalData();
  const dataTableRef = useRef<DataTableRef>(null);
  const navigate = useNavigate();
  const { handleError } = useError();
  const { unblockUser } = useUnblockUser();
  const { showPopover, hidePopover, showConfirmPopover } = usePopup();

  const columns: Column<UserDetailsDto>[] = [
    { id: 'id', title: t('columns.id'), isSortable: true, align: 'center', renderCell: data => data.id },
    {
      id: 'blockadeDate',
      title: t('columns.date'),
      isSortable: true,
      minWidth: 100,
      renderCell: data => data.blockadeDate && dateUtils.formatDate(new Date(data.blockadeDate)),
    },
    {
      id: 'email',
      title: t('columns.username'),
      isSortable: true,
      renderCell: data => data.email,
    },
    {
      id: 'firstName',
      title: t('columns.first-name'),
      minWidth: 100,
      isSortable: true,
      renderCell: data => data.firstName,
    },
    {
      id: 'lastName',
      title: t('columns.last-name'),
      minWidth: 100,
      isSortable: true,
      renderCell: data => data.lastName,
    },
    {
      id: 'comments',
      title: t('columns.comments'),
      minWidth: 200,
      renderCell: data => data.lastComment || '',
    },
    {
      id: 'blockadeReason',
      title: t('columns.reason'),
      isSortable: true,
      renderCell: data => data.blockadeReason,
    },
    {
      id: 'actions',
      title: t('columns.actions'),
      align: 'center',
      minWidth: 100,
      renderCell: data => {
        return (
          <UserActions
            user={data}
            onUnblockPressed={element => {
              showPopover(
                <UnblockUserPopover userData={data} onUnblockPressed={() => handleUnblockUser(data.id)} />,
                element
              );
            }}
            onCloseAccount={() => {
              const username = `${data.firstName} ${data.lastName}`;
              showConfirmPopover(t('confirm-close-account', { username }), () => handleCloseAccount(data.id));
            }}
            onContactUser={() => openConversation(data.id)}
          />
        );
      },
    },
  ];

  const filters: TableFilter[] = [
    {
      type: 'dropdown',
      name: 'blockadeReason',
      label: t('filters.reason'),
      options: blockadeReasons,
    },
    { type: 'date', name: 'dateFrom', label: t('filters.date-from') },
    { type: 'date', name: 'dateTo', label: t('filters.date-to') },
    { type: 'search', name: 'searchValue', label: t('common:search') },
  ];

  const handleOnTableStateChanged = useCallback((tableState: TableFilterStateDto<Map<string, any>>) => {
    const userFilter = convertFiltersToUserFilterDto(tableState);
    return userService.fetchUsers(userFilter, true).then(response => response.data);
  }, []);

  const handleUnblockUser = (userId: number) => {
    unblockUser(userId, dataTableRef.current?.refresh);
    hidePopover();
  };

  const openConversation = (endUserId: number) =>
    messageService
      .createConversation(endUserId)
      .then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));

  const handleCloseAccount = (userId: number) => {
    hidePopover();
    userService
      .closeAccount(userId)
      .then(() => dataTableRef.current?.refresh())
      .catch(e => handleError(e, e.response?.data && JSON.stringify(e.response?.data, null, 2)));
  };

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={handleOnTableStateChanged}
        />
      </Container>
    </PageContainer>
  );
};

export default UsersBlocked;
