import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { Column } from '../../../../components/table/DataTable';
import { DataTableRef } from '../../../../components/table/dataTableRef';
import { TableFilter } from '../../../../components/table/FiltersPanel';
import DataTableWithFilters from '../../../../components/table/DataTableWithFilters';
import {
  PAYMENT_AGENT_OPTIONS,
  TRANSACTION_STATUS_OPTIONS,
  TransactionInfoDto,
} from '../../../../services/Payment/paymentService.dto';
import { dateUtils } from '../../../../utils/dateUtils';
import CellWithNavigate from '../../../../components/table/CellWithNavigate';
import CellWithPopover from '../../../../components/table/CellWithPopover';
import UserDetailsCard from '../../../users/components/UserDetailsCard';
import { usePopup } from '../../../../providers/PopupProvider';
import { useTransactionFilters } from '../hooks/useTransactionFilters';
import TransactionErrorsPopover from './TransactionErrorsPopover';
import TransactionBillingDataCard from './TransactionBillingDataCard';

const TRANSACTION_FILTER_MAX_AMOUNT = 120000;

const TransactionsTable = () => {
  const { t } = useTranslation('payments');
  const dataTableRef = useRef<DataTableRef>(null);
  const { onTableFiltered, filterInitState } = useTransactionFilters();
  const { showPopover } = usePopup();

  const columns: Column<TransactionInfoDto>[] = [
    {
      id: 'transactionId',
      title: t('transactions.columns.transaction-id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.transactionId,
    },
    {
      id: 'transactionDate',
      title: t('transactions.columns.transaction-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDateDate(new Date(data.transactionDate)),
    },
    {
      id: 'status',
      title: t('transactions.columns.status'),
      align: 'center',
      renderCell: data => data.transactionStatus,
    },
    {
      id: 'amount',
      title: t('transactions.columns.total-amount'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.amount,
    },
    {
      id: 'currency',
      title: t('transactions.columns.currency'),
      align: 'center',
      renderCell: data => data.currency,
    },
    {
      id: 'errors',
      title: t('transactions.columns.errors'),
      align: 'center',
      renderCell: data =>
        data.errors ? (
          <CellWithPopover popover={<TransactionErrorsPopover transactionId={data.transactionId} />}>
            {t('common:yes')}
          </CellWithPopover>
        ) : (
          t('common:no')
        ),
    },
    {
      id: 'method',
      title: t('transactions.columns.method-type'),
      align: 'center',
      renderCell: data => data.methodType,
    },
    {
      id: 'provider',
      title: t('transactions.columns.provider'),
      align: 'center',
      renderCell: data => data.provider,
    },
    {
      id: 'transactionType',
      title: t('transactions.columns.transaction-type'),
      align: 'center',
      renderCell: data => data.transactionType,
    },
    {
      id: 'paymentAgent',
      title: t('transactions.columns.payment-agent'),
      align: 'center',
      renderCell: data => data.paymentAgent,
    },
    {
      id: 'payerId',
      title: t('transactions.columns.payer-id'),
      align: 'center',
      renderCell: data =>
        data.payerId && (
          <CellWithPopover popover={<UserDetailsCard userId={data.payerId} />}>{data.payerId}</CellWithPopover>
        ),
    },
    {
      id: 'orderId',
      title: t('transactions.columns.order-id'),
      align: 'center',
      renderCell: data => (
        <CellWithNavigate
          url={`/orders/list/${data.orderId}`}
          title={t('transactions.go-to-orders-tooltip', { id: data.orderId })}>
          {data.orderId}
        </CellWithNavigate>
      ),
    },
    {
      id: 'billingData',
      title: t('transactions.columns.billing-data'),
      align: 'center',
      renderCell: data => {
        const hasBillingData = data.transactionType === 'AUTH_AND_CAPTURE' || data.transactionType === 'AUTH_ONLY';
        if (!hasBillingData) return <></>;
        return (
          <SearchSharpIcon
            onClick={() =>
              showPopover(<TransactionBillingDataCard transactionId={data.transactionId} buyerId={data.payerId} />)
            }
            style={{ fontSize: 18, cursor: 'pointer' }}
          />
        );
      },
    },
    {
      id: 'overLimit',
      title: t('transactions.columns.over-limit'),
      align: 'center',
      renderCell: data => (data.overLimit ? t('common:yes') : t('common:no')),
    },
  ];

  const filters: TableFilter[] = [
    {
      type: 'numeric',
      name: 'id',
      label: t('transactions.filters.id'),
    },
    {
      type: 'numeric',
      name: 'orderId',
      label: t('transactions.filters.order-id'),
    },
    {
      type: 'multi-choice',
      name: 'status',
      options: [...TRANSACTION_STATUS_OPTIONS],
      label: t('transactions.filters.status'),
    },
    {
      type: 'multi-choice',
      name: 'paymentAgent',
      options: PAYMENT_AGENT_OPTIONS,
      label: t('transactions.filters.payment-agent'),
    },
    {
      type: 'numeric',
      name: 'payerId',
      label: t('transactions.filters.payer-id'),
    },
    {
      type: 'date',
      name: 'date',
      label: t('transactions.filters.date'),
    },
    {
      type: 'checkbox',
      name: 'errors',
      label: t('transactions.filters.errors'),
      checked: false,
    },
    {
      type: 'checkbox',
      name: 'overLimit',
      label: t('transactions.filters.over-limit'),
      checked: false,
    },
    {
      type: 'range',
      name: 'amount',
      label: t('transactions.filters.amount'),
      max: TRANSACTION_FILTER_MAX_AMOUNT,
    },
  ];

  return (
    <>
      <DataTableWithFilters
        ref={dataTableRef}
        columns={columns}
        filters={filters}
        hideApplyButton={true}
        refreshOnFiltersChange={true}
        onTableStateChanged={onTableFiltered}
        filterInitState={filterInitState}
      />
    </>
  );
};

export default TransactionsTable;
